// tslint:disable
import { Component, Injector, ViewChild } from "@angular/core";

import {
  CMFormEditComponent,
  CMInputConfig,
  CMFormGlobaisComponent,
  CMGridEditConfig,
  CMGridEditConfigColumn,
  CMGridEditConfigMore,
} from "./../../@cmcore/component";
import {
  PessoaBrowseComponent,
  TransportadoraBrowseComponent,
  ProdutoBrowseComponent,
  CFOPBrowseComponent,
  PadraoDeOperacaoEditComponent,
  ContaBancariaBrowseComponent,
  ContaContabilBrowseComponent,
} from "./../index";
import { CMValidator } from "./../../@cmcore/validator";
import { FuncsGlobais } from "../funcs.globais";

@Component({
  selector: "nfe-recebimento-edit",
  templateUrl: "nfe-recebimento-edit.component.html",
})
export class NFeRecebimentoEditComponent extends CMFormEditComponent {
  getFirstComponentName() {
    return "model_infnfe_ide_nnf_nferecebimento";
  }

  getTitle() {
    let r: string = "NF-e de Recebimento";
    let tmp: string = this.fieldByName("infnfe.ide.nnf");
    if (tmp !== "0") r += " - #" + tmp;
    tmp = this.fieldByName("infnfe.dest.pessoa.nome");
    if (tmp !== "") r += " - " + tmp;
    return r;
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "nferecebimento";
    this.setModelConfig();
  }

  afterGetModelData() {
    super.afterGetModelData();
    if (!this.disabledAll)
      this.disabledAll = this.model.infnfe.podeeditar !== "Sim";
    if (this.model.infnfe.cobrpagar.contacontabil === undefined){
      this.setContaContabilFornecedores();
    }
    
  }

  setContaContabilFornecedores() {
    this.model.infnfe.cobrpagar.contacontabil = Object.assign({}, this.currentUserStorage.contacontabilfornecedores);
    console.log("this.model.infnf: ", this.currentUserStorage);
  }

  setModelConfig() {
    this.modelConfig = {
      impostocalculado: new CMInputConfig({
        form: this,
        caption: "Soma valores automaticamente",
      }),
      infnfe: {
        ide: {
          nnf: new CMInputConfig({
            form: this,
            caption: "#",
            disabled: true,
            required: true,
          }),
          serie: new CMInputConfig({
            form: this,
            caption: "Série",
            dataType: "integer",
            required: true,
          }),
          nnf_nferecebimento: new CMInputConfig({
            form: this,
            caption: "Número",
            dataType: "integer",
            required: true,
          }),
          dhemi: new CMInputConfig({
            form: this,
            caption: "Data emissão",
            mask: "99/99/9999",
            required: true,
          }),
        },
        dest: {
          pessoa: new CMInputConfig({
            form: this,
            caption: "Emitente",
            required: true,
            pesqFunc: (sender) => {
              PessoaBrowseComponent.pesquisa(
                sender,
                this.model.infnfe.dest,
                "Fornecedor"
              );
            },
          }),
        },
        det: new CMGridEditConfig({
          form: this,
          objName: "det",
          caption: "Produtos",
          onNewItem: (model) => {
            Object.assign(model, {
              prod: {
                qcom: "1,00",
                vuncom: "0,00",
                vprod: "0,00",
                vdesc: "0,00",
                pdesc: "0,00",
                vtotal: "0,00",
              },
              estoque: [],
            });
          },
          customValidItem: (item) => {
            return this.validEstoque(item);
          },
          onRemoveItem: (item) => {
            this.onRemoveItem(item);
          },

          moreActions: [
            new CMGridEditConfigMore({
              hint: "Mais detalhes",
              icone: () => {
                if (this.disabledAll) return "visibility";
                else return "edit";
              },
            }),
          ],
          extraColumns: {
            produto_descr: new CMInputConfig({
              form: this,
              caption: "Descrição",
              gridConfig: new CMGridEditConfigColumn({
                ordem: 2,
                getValue: (row) =>
                  this.getValueObject(row, "prod.produto.descr"),
              }),
            }),
          },
          fields: {
            prod: {
              produto: new CMInputConfig({
                form: this,
                caption: "Produto",
                required: true,
                maxlength: 30,
                padInfo: { size: 30 },
                onExit: () => {
                  console.log(this.modelConfig.infnfe.det.current);
                },
                gridConfig: new CMGridEditConfigColumn({
                  ordem: 1,
                  width: "300px",
                  tipo: "input-pesquisa",
                  getValue: (row) => {
                    return this.getValueObject(row, "prod.produto.codigo");
                  },
                }),
                pesqFunc: (sender) => {
                  ProdutoBrowseComponent.pesquisa(
                    sender,
                    this.modelConfig.infnfe.det.current.prod,
                    "Todos",
                    undefined,
                    () => {
                      if(this.currentProdutoUsaGrade()){
                        this.modelConfig.infnfe.det.current.prod.qcom = "0,00";

                        let grades = this.modelConfig.infnfe.det.current.prod.produto.grades;

                        grades.forEach((grade) => {
                          this.modelConfig.infnfe.det.current.estoque.push({
                            "datavalidadelote": "",
                            "grade": grade,
                            "id": CMInputConfig.getRandomInt(1, 9999) * -1,
                            "numerolote": "",
                            "qcom": "0,00"
                          })
                        });
                      }
                      else{
                        this.modelConfig.infnfe.det.current.prod.qcom = "1,00";
                      };
                    }
                  );
                },
              }),
              qcom: new CMInputConfig({
                form: this,
                caption: "Quantidade",
                dataType: "double(2)",
                gridConfig: new CMGridEditConfigColumn({
                  width: "150px",
                  ordem: 3,
                }),
                hintButton: "Editar",
                iconButton: () => {
                  if (this.disabledAll) return "visibility";
                  else return "edit";
                },
                showButtonClear: false,
                pesqOnlyFunc: (row) => this.mostraDetalhesQuantidade(row),
                pesqFunc: (sender) => {
                  if (
                    this.mostraDetalhesQuantidade(
                      this.modelConfig.infnfe.det.current
                    )
                  )
                    this.modelConfig.infnfe.det.extraClick(
                      new CMGridEditConfigMore({
                        index: 0,
                        title: "Detalhes da Quantidade",
                      })
                    );
                },
                onExit: () => {
                  this.calcTotalItem();
                },
                showButtonFunc: (show, row) =>
                  this.mostraDetalhesQuantidade(row),
                required: true,
              }),
              vuncom: new CMInputConfig({
                form: this,
                caption: "Valor unitário",
                dataType: "double(2)",
                gridConfig: new CMGridEditConfigColumn({
                  width: "150px",
                  ordem: 4,
                }),
                onExit: () => {
                  this.calcTotalItem();
                },
                required: true,
              }),
              vtotal: new CMInputConfig({
                form: this,
                caption: "Valor total",
                dataType: "double(2)",
                gridConfig: new CMGridEditConfigColumn({
                  width: "150px",
                  ordem: 5,
                }),
                required: true,
                disabledFunc: () => {
                  return this.somaValoresAutomaticamente;
                },
              }),
              novopreco1: new CMInputConfig({
                form: this,
                caption: "Novo Preço " + FuncsGlobais.paramEmpresa.nomePreco1,
                dataType: "double(2)",
                gridConfig: new CMGridEditConfigColumn({
                  width: "150px",
                  ordem: 6,
                }),
              }),
              vdesc: new CMInputConfig({
                form: this,
                caption: "Valor de desconto",
                dataType: "double(2)",
                onExit: () => {
                  this.calcTotalItem("v");
                },
              }),
              pdesc: new CMInputConfig({
                form: this,
                caption: "% de desconto",
                dataType: "double(2)",
                onExit: () => {
                  this.calcTotalItem("p");
                },
              }),
              vfrete: new CMInputConfig({
                form: this,
                caption: "Valor do frete",
                dataType: "double(2)",
                onEnter: () => {
                  this.onEnterItemDespesa("vfrete");
                },
                onExit: () => {
                  this.calcTotalDespesa("vfrete");
                },
              }),
              vseg: new CMInputConfig({
                form: this,
                caption: "Valor do seguro",
                dataType: "double(2)",
                onEnter: () => {
                  this.onEnterItemDespesa("vseg");
                },
                onExit: () => {
                  this.calcTotalDespesa("vseg");
                },
              }),
              voutro: new CMInputConfig({
                form: this,
                caption: "Outras despesas acessórias",
                dataType: "double(2)",
                onEnter: () => {
                  this.onEnterItemDespesa("voutro");
                },
                onExit: () => {
                  this.calcTotalDespesa("voutro");
                },
              }),
              cfop: new CMInputConfig({
                form: this,
                caption: "CFOP",
                mask: "9.999",
                customvalidators: [
                  new CMValidator(() => {
                    return this.validCFOPAtivo();
                  }),
                ],
                pesqFunc: (sender) => {
                  CFOPBrowseComponent.pesquisa(
                    sender,
                    this.modelConfig.infnfe.det.current.prod,
                    "cfop",
                    this.model.infnfe.ide.tpnf,
                    this.model.infnfe.ide.finnfe
                  );
                },
              }),
              indtot: new CMInputConfig({
                form: this,
                caption: "Valor do Item entra no valor total da NF-e",
                values: [
                  { value: "NaoCompoeValorTotal", text: "Não" },
                  { value: "CompoeValorTotal", text: "Sim" },
                ],
              }),
            },
            imposto: {
              vtottrib: new CMInputConfig({
                form: this,
                caption: "Valor dos tributos aproximados",
                dataType: "double(2)",
                onEnter: () => {
                  this.onEnterItemImp(
                    this.modelConfig.infnfe.det.current.imposto,
                    "vtottrib"
                  );
                },
                onExit: () => {
                  this.calcTotalImp(
                    this.modelConfig.infnfe.det.current.imposto,
                    "vtottrib"
                  );
                },
              }),
              ipi: {
                clenq: new CMInputConfig({
                  form: this,
                  caption: "Código de Enquadramento Legal do IPI",
                }),
                cnpjprod: new CMInputConfig({
                  form: this,
                  caption: "CNPJ do produtor da mercadoria.",
                }),
                cselo: new CMInputConfig({
                  form: this,
                  caption: "CNPJ do produtor da mercadoria.",
                }),
                qselo: new CMInputConfig({
                  form: this,
                  caption: "Quantidade de Selos de Controle.",
                  dataType: "double(2)"
                }),
                cenq: new CMInputConfig({
                  form: this,
                  caption: "Código de Enquadramento Legal do IPI",
                  dataType: "integer"
                }),
                cst: new CMInputConfig({
                  form: this,
                  caption: "CST",
                  dataType: "integer"
                }),
                vbc: new CMInputConfig({
                  form: this,
                  caption: "Valor da Base de Cálculo",
                  dataType: "double(2)"
                }),
                pipi: new CMInputConfig({
                  form: this,
                  caption: "Alíquota do IPI",
                  dataType: "double(2)"
                }),
                qunid: new CMInputConfig({
                  form: this,
                  caption: "Quantidade total na unidade padrão para tributação",
                  dataType: "double(2)"
                }),
                vunid: new CMInputConfig({
                  form: this,
                  caption: "Valor por Unidade Tributável",
                  dataType: "double(2)"
                }),
                vipi: new CMInputConfig({
                  form: this,
                  caption: "Valor do IPI ",
                  dataType: "double(2)"
                }),
              },
              icms: {
                csosn: new CMInputConfig({
                  form: this,
                  caption: "CSOSN",
                  dataType: "integer",
                  values: PadraoDeOperacaoEditComponent.CSOSN,
                }),
                modbc: new CMInputConfig({
                  form: this,
                  caption:
                    "Modalidade de determinação da BC do ICMS (CSOSN 900)",
                  dataType: "integer",
                }),
                vbc: new CMInputConfig({
                  form: this,
                  caption: "Base de Cálculo (CSOSN 900)",
                  dataType: "double(2)",
                  onEnter: () => {
                    this.onEnterItemImp(
                      this.modelConfig.infnfe.det.current.imposto.icms,
                      "vbc"
                    );
                  },
                  onExit: () => {
                    this.calcTotalImp(
                      this.modelConfig.infnfe.det.current.imposto.icms,
                      "vbc"
                    );
                  },
                }),
                picms: new CMInputConfig({
                  form: this,
                  caption: "Alíquota (CSOSN 900)",
                  dataType: "double(2)",
                }),
                vicms: new CMInputConfig({
                  form: this,
                  caption: "Valor do ICMS (CSOSN 900)",
                  dataType: "double(2)",
                  onEnter: () => {
                    this.onEnterItemImp(
                      this.modelConfig.infnfe.det.current.imposto.icms,
                      "vicms"
                    );
                  },
                  onExit: () => {
                    this.calcTotalImp(
                      this.modelConfig.infnfe.det.current.imposto.icms,
                      "vicms"
                    );
                  },
                }),
                pcredsn: new CMInputConfig({
                  form: this,
                  caption:
                    "Alíquota aplicável de cálculo do crédito (Simples Nacional)",
                  dataType: "double(2)",
                }),
                vcredicmssn: new CMInputConfig({
                  form: this,
                  caption:
                    "Valor crédito do ICMS que pode ser aproveitado nos termos do art. 23 da LC 123 (Simples Nacional)",
                  dataType: "double(2)",
                }),
                predbc: new CMInputConfig({
                  form: this,
                  caption: "Percentual da Redução de BC (CSOSN 900)",
                  dataType: "double(2)",
                }),
              },
              icmsst: {
                modbcst: new CMInputConfig({
                  form: this,
                  caption: "Modalidade de determinação da BC do ICMS ST",
                  dataType: "integer",
                }),
                vbcst: new CMInputConfig({
                  form: this,
                  caption: "Base de Cálculo",
                  dataType: "double(2)",
                  onEnter: () => {
                    this.onEnterItemImp(
                      this.modelConfig.infnfe.det.current.imposto.icmsst,
                      "vbcst"
                    );
                  },
                  onExit: () => {
                    this.calcTotalImp(
                      this.modelConfig.infnfe.det.current.imposto.icmsst,
                      "vbcst"
                    );
                  },
                }),
                predbcst: new CMInputConfig({
                  form: this,
                  caption: "Percentual da Redução de BC do ICMS ST",
                  dataType: "double(2)",
                }),
                pmvast: new CMInputConfig({
                  form: this,
                  caption: "MVA",
                  dataType: "double(2)",
                }),
                picmsst: new CMInputConfig({
                  form: this,
                  caption: "Alíquota",
                  dataType: "double(2)",
                }),
                vicmsst: new CMInputConfig({
                  form: this,
                  caption: "Valor do ICMS",
                  dataType: "double(2)",
                  onEnter: () => {
                    this.onEnterItemImp(
                      this.modelConfig.infnfe.det.current.imposto.icmsst,
                      "vicmsst"
                    );
                  },
                  onExit: () => {
                    this.calcTotalImp(
                      this.modelConfig.infnfe.det.current.imposto.icmsst,
                      "vicmsst"
                    );
                  },
                }),
                vbcstret: new CMInputConfig({
                  form: this,
                  caption: "Base Cálculo (CSOSN 500)",
                  dataType: "double(2)",
                }),
                vicmsstret: new CMInputConfig({
                  form: this,
                  caption: "Valor ICMS (CSOSN 500)",
                  dataType: "double(2)",
                }),
              },
              pis: {
                cst: new CMInputConfig({
                  form: this,
                  caption: "CST",
                  dataType: "integer",
                  values: PadraoDeOperacaoEditComponent.CST_PIS_COFINS,
                }),
                vbc: new CMInputConfig({
                  form: this,
                  caption: "Base de Cálculo",
                  dataType: "double(2)",
                }),
                ppis: new CMInputConfig({
                  form: this,
                  caption: "Alíquota",
                  dataType: "double(2)",
                }),
                vpis: new CMInputConfig({
                  form: this,
                  caption: "Valor do PIS",
                  dataType: "double(2)",
                  onEnter: () => {
                    this.onEnterItemImp(
                      this.modelConfig.infnfe.det.current.imposto.pis,
                      "vpis"
                    );
                  },
                  onExit: () => {
                    this.calcTotalImp(
                      this.modelConfig.infnfe.det.current.imposto.pis,
                      "vpis"
                    );
                  },
                }),
              },
              cofins: {
                cst: new CMInputConfig({
                  form: this,
                  caption: "CST",
                  dataType: "integer",
                  values: PadraoDeOperacaoEditComponent.CST_PIS_COFINS,
                }),
                vbc: new CMInputConfig({
                  form: this,
                  caption: "Base de Cálculo",
                  dataType: "double(2)",
                }),
                pcofins: new CMInputConfig({
                  form: this,
                  caption: "Alíquota",
                  dataType: "double(2)",
                }),
                vcofins: new CMInputConfig({
                  form: this,
                  caption: "Valor da COFINS",
                  dataType: "double(2)",
                  onEnter: () => {
                    this.onEnterItemImp(
                      this.modelConfig.infnfe.det.current.imposto.cofins,
                      "vcofins"
                    );
                  },
                  onExit: () => {
                    this.calcTotalImp(
                      this.modelConfig.infnfe.det.current.imposto.cofins,
                      "vcofins"
                    );
                  },
                }),
              },
            },
            estoque: new CMGridEditConfig({
              form: this,
              required: false,
              onRemoveItem: (item) => {
                this.onRemoveItemEstoque(item);
              },
              fields: {
                grade: new CMInputConfig({
                  form: this,
                  caption: "Grade",
                  required: true,
                  pesqOnly: true,
                  gridConfig: new CMGridEditConfigColumn({
                    tipo: "input-pesquisa",
                    getValue: (row) => {
                      return this.getValueObject(row, "grade.descricao");
                    },
                  }),
                  pesqFunc: (sender) => {
                    ProdutoBrowseComponent.pesquisaGrade(
                      sender,
                      this.modelConfig.infnfe.det.fields.estoque.current,
                      this.modelConfig.infnfe.det.current.prod.produto.id
                    );
                  },
                  visibleFunc: () => {
                    return this.currentProdutoUsaGrade();
                  },
                }),
                numerolote: new CMInputConfig({
                  form: this,
                  caption: "Número Lote",
                  dataType: "string",
                  maxlength: 20,
                  gridConfig: new CMGridEditConfigColumn(),
                  requiredFunc: () => {
                    return this.currentProdutoUsaLote();
                  },
                  visibleFunc: () => {
                    return this.currentProdutoUsaLote();
                  },
                  pesqFunc: (sender) => {
                    ProdutoBrowseComponent.pesquisaLoteOnSelect(
                      sender,
                      this.modelConfig.infnfe.det.fields.estoque.current,
                      this.modelConfig.infnfe.det.current.prod.produto.id,
                      this.modelConfig.infnfe.det.fields.estoque.current.grade.id,
                    );
                  },
                }),
                datavalidadelote: new CMInputConfig({
                  form: this,
                  caption: "Data Validade Lote",
                  mask: "99/99/9999",
                  gridConfig: new CMGridEditConfigColumn(),
                  requiredFunc: () => {
                    return this.currentProdutoUsaValidadeLote();
                  },
                  visibleFunc: () => {
                    return this.currentProdutoUsaValidadeLote();
                  },
                }),
                qcom: new CMInputConfig({
                  form: this,
                  caption: "Quantidade",
                  dataType: "double(2)",
                  gridConfig: new CMGridEditConfigColumn(),
                  onExit: () => {
                    this.calcTotalItemEstoque();
                  },
                }),
              },
            }),
          },
        }),
        total: {
          icmstot: {
            vbc: new CMInputConfig({
              form: this,
              caption: "Base de cálculo do ICMS",
              dataType: "double(2)",
              disabledFunc: () => {
                return this.somaValoresAutomaticamente;
              },
            }),
            vprod: new CMInputConfig({
              form: this,
              caption: "Valor total dos produtos",
              dataType: "double(2)",
              required: true,
              disabledFunc: () => {
                return this.somaValoresAutomaticamente;
              },
            }),
            vdesc: new CMInputConfig({
              form: this,
              caption: "Valor de desconto",
              dataType: "double(2)",
              onEnter: () => {
                this.onEnterDescTotal("v");
              },
              onExit: () => {
                this.calcDescTotal("v");
              },
              disabledFunc: () => {
                return this.somaValoresAutomaticamente;
              },
            }),
            pdesc: new CMInputConfig({
              form: this,
              caption: "% de desconto",
              dataType: "double(2)",
              onEnter: () => {
                this.onEnterDescTotal("p");
              },
              onExit: () => {
                this.calcDescTotal("p");
              },
              disabledFunc: () => {
                return this.somaValoresAutomaticamente;
              },
            }),
            vicms: new CMInputConfig({
              form: this,
              caption: "Valor do ICMS",
              dataType: "double(2)",
              disabledFunc: () => {
                return this.somaValoresAutomaticamente;
              },
            }),
            vbcst: new CMInputConfig({
              form: this,
              caption: "Base de cálculo do ICMS ST",
              dataType: "double(2)",
              disabledFunc: () => {
                return this.somaValoresAutomaticamente;
              },
            }),
            vst: new CMInputConfig({
              form: this,
              caption: "Valor do ICMS ST",
              dataType: "double(2)",
              disabledFunc: () => {
                return this.somaValoresAutomaticamente;
              },
            }),
            vfrete: new CMInputConfig({
              form: this,
              caption: "Valor do frete",
              dataType: "double(2)",
              disabledFunc: () => {
                return this.somaValoresAutomaticamente;
              },
            }),
            vseg: new CMInputConfig({
              form: this,
              caption: "Valor do seguro",
              dataType: "double(2)",
              disabledFunc: () => {
                return this.somaValoresAutomaticamente;
              },
            }),
            vpis: new CMInputConfig({
              form: this,
              caption: "Valor do PIS",
              dataType: "double(2)",
              disabledFunc: () => {
                return this.somaValoresAutomaticamente;
              },
            }),
            vcofins: new CMInputConfig({
              form: this,
              caption: "Valor da COFINS",
              dataType: "double(2)",
              disabledFunc: () => {
                return this.somaValoresAutomaticamente;
              },
            }),
            voutro: new CMInputConfig({
              form: this,
              caption: "Outras despesas acessórias",
              dataType: "double(2)",
              disabledFunc: () => {
                return this.somaValoresAutomaticamente;
              },
            }),
            vnf: new CMInputConfig({
              form: this,
              caption: "Valor total",
              dataType: "double(2)",
              disabledFunc: () => {
                return this.somaValoresAutomaticamente;
              },
            }),
            vtottrib: new CMInputConfig({
              form: this,
              caption: "Valor dos tributos aproximados",
              dataType: "double(2)",
              disabledFunc: () => {
                return this.somaValoresAutomaticamente;
              },
            }),
          },
        },
        transp: {
          modfrete: new CMInputConfig({
            form: this,
            caption: "Modalidade do frete",
            values: [
              { value: "PorContaDoEmitente", text: "Por conta do emitente" },
              {
                value: "PorContaDoDestinatario",
                text: "Por conta do destinatário/remetente",
              },
              { value: "PorContaDeTerceiros", text: "Por conta de terceiros" },
              { value: "SemFrete", text: "Sem frete" },
            ],
          }),
          transportadora: new CMInputConfig({
            form: this,
            caption: "Transportadora",
            pesqFunc: (sender) => {
              TransportadoraBrowseComponent.pesquisa(
                sender,
                this.model.infnfe.transp
              );
            },
          }),
          placa: new CMInputConfig({
            form: this,
            caption: "Placa",
            maxlength: 7,
          }),
          uf_placa: new CMInputConfig({
            form: this,
            caption: "Estado",
            values: [
              { value: "AC" },
              { value: "AL" },
              { value: "AP" },
              { value: "AM" },
              { value: "BA" },
              { value: "CE" },
              { value: "DF" },
              { value: "ES" },
              { value: "GO" },
              { value: "MA" },
              { value: "MT" },
              { value: "MS" },
              { value: "MG" },
              { value: "PR" },
              { value: "PB" },
              { value: "PA" },
              { value: "PE" },
              { value: "PI" },
              { value: "RJ" },
              { value: "RN" },
              { value: "RS" },
              { value: "RO" },
              { value: "RR" },
              { value: "SC" },
              { value: "SE" },
              { value: "SP" },
              { value: "TO" },
            ],
          }),
          rntc: new CMInputConfig({
            form: this,
            caption: "RNTC / ANTT",
            maxlength: 20,
          }),
          vol: new CMGridEditConfig({
            form: this,
            required: false,
            onNewItem: (model) => {
              Object.assign(model, {
                qvol: "0",
                pesol: "0,000",
                pesob: "0,000",
              });
            },
            fields: {
              qvol: new CMInputConfig({
                form: this,
                caption: "Quantidade",
                gridConfig: new CMGridEditConfigColumn(),
                dataType: "integer",
              }),
              esp: new CMInputConfig({
                form: this,
                caption: "Espécie",
                gridConfig: new CMGridEditConfigColumn(),
                maxlength: 60,
              }),
              marca: new CMInputConfig({
                form: this,
                caption: "Marca",
                gridConfig: new CMGridEditConfigColumn(),
                maxlength: 60,
              }),
              nvol: new CMInputConfig({
                form: this,
                caption: "Numeração",
                gridConfig: new CMGridEditConfigColumn(),
                maxlength: 60,
              }),
              pesol: new CMInputConfig({
                form: this,
                caption: "Peso Líquido (em kg)",
                gridConfig: new CMGridEditConfigColumn(),
                dataType: "double(3)",
              }),
              pesob: new CMInputConfig({
                form: this,
                caption: "Peso Bruto (em kg)",
                gridConfig: new CMGridEditConfigColumn(),
                dataType: "double(3)",
              }),
            },
          }),
        },

        cobrpagar: {
          vdesconto: new CMInputConfig({
            form: this,
            caption: "Desconto financeiro",
            dataType: "double(2)",
            onExit: () => {
              this.calcValorLiquidoDuplicatas();
            },
          }),
          vliquido: new CMInputConfig({
            form: this,
            caption: "Valor líquido",
            dataType: "double(2)",
            disabledFunc: () => {
              return this.somaValoresAutomaticamente;
            },
          }),
          contabancaria: new CMInputConfig({
            form: this,
            caption: "Conta Bancária",
            pesqFunc: (sender) => {
              ContaBancariaBrowseComponent.pesquisa(sender, this.model.infnfe.cobrpagar, "contabancaria");
              console.log('thismodel: ', this.model);
            },
          }),
          contacontabil: new CMInputConfig({
                      form: this,
                      caption: "Conta Contabil",
                      pesqFunc: (sender) => {
                        ContaContabilBrowseComponent.pesquisa(sender, this.model.infnfe.cobrpagar, "contacontabil");
                        console.log('thismodel: ', this.model);
                      },
          }),
          duplicatas: new CMGridEditConfig({
            form: this,
            caption: "Duplicatas",
            customValidItem: (item) => {
              return this.validDuplicata(item);
            },
            requiredFunc: () => {
              return this.model.infnfe.tipopagamento !== "AVista";
            },
            moreActions: [
              new CMGridEditConfigMore({
                hint: "Mais detalhes",
                icone: () => {
                  if (this.disabledAll) return "visibility";
                  else return "edit";
                },
              }),
            ],
            fields: {
              nduplicata: new CMInputConfig({
                form: this,
                caption: "Número da Duplicata",
                maxlength: 60,
                gridConfig: new CMGridEditConfigColumn(),
                required: true,
              }),
              dvenc: new CMInputConfig({
                form: this,
                caption: "Data de vencimento",
                mask: "99/99/9999",
                gridConfig: new CMGridEditConfigColumn(),
                required: true,
              }),
              valor: new CMInputConfig({
                form: this,
                caption: "Valor da duplicata",
                dataType: "double(2)",
                gridConfig: new CMGridEditConfigColumn(),
              }),
              observacao: new CMInputConfig({
                form: this,
                caption: "Observação",
              }),
            },
          }),
        },

        infadic: {
          infadfisco: new CMInputConfig({
            form: this,
            caption: "Informações adicionais de interesse do fisco",
          }),
          infcpl: new CMInputConfig({
            form: this,
            caption: "Informações Complementares de interesse do Contribuinte",
          }),
        },
        tipopagamento: new CMInputConfig({
          form: this,
          caption: "Tipo de Pagamento",
          values: [
            { value: "AVista", text: "a Vista" },
            { value: "FormaPagamento", text: "Duplicatas" },
          ],
        }),
        chaveacesso: new CMInputConfig({
          form: this,
          caption: "Chave de acesso",
        }),
      },
    };
  }

  validEstoque(item: any) {
    let r: string = "";
    console.log('item ', item);
    console.log('item.qcom ', item.prod.qcom);

    if (this.valueToFloat(item.prod.qcom, 0) <= 0)
      r = "Valor total deve ser maior que zero";
    return r;
  }

  onRemoveItemEstoque(item: any) {
    let itemsEstoque = this.modelConfig.infnfe.det.current;
    let qtotal = 0;
    
    itemsEstoque.estoque.forEach((est) => {
      if (item !== est){
        qtotal += this.valueToFloat(est.qcom);
      }
    });
    itemsEstoque.prod.qcom = this.floatToValue(qtotal);
    this.calcTotalItem(this.modelConfig.infnfe.det.current.prod);
  }

  calcTotalItemEstoque() {
    let item = this.modelConfig.infnfe.det.current;
    let qtotal = 0;
    item.estoque.forEach((est) => {
      qtotal += this.valueToFloat(est.qcom);
    });
    item.prod.qcom = this.floatToValue(qtotal);
    this.calcTotalItem();
  }

  validDuplicata(duplicata: any) {
    let r: string = "";
    if (this.model.infnfe.tipopagamento !== "AVista") {
      let dvenc = this.valueToDate(duplicata.dvenc);
      let data = this.valueToDate(this.model.infnfe.ide.dhemi);
      if (dvenc < data)
        r = "Data de vencimento deve ser maior que a data de emissão";
    }
    return r;
  }

  validCFOPAtivo() {
    let r: string = "";
    let item = this.modelConfig.infnfe.det.current;
    if (item && item.prod && item.prod.cfop && item.prod.cfop.ativo !== "Sim") {
      r = "CFOP Inativo. Utilize a pesquisa para verificar os CFOPs ativos.";
    }
    return r;
  }

  mostraDetalhesQuantidade(row) {
    let r = false;
    if (row && row.prod && row.prod.produto) {
      r =
        row.prod.produto.usagrade1 === "Sim" ||
        row.prod.produto.usagrade2 === "Sim" ||
        row.prod.produto.usalote === "Sim";
    }
    return r;
  }

  currentProdutoUsaLote() {
    return (
      this.getValueObject(
        this.modelConfig.infnfe.det.current,
        "prod.produto.usalote"
      ) === "Sim"
    );
  }

  currentProdutoUsaValidadeLote() {
    if (this.getValueObject(this.model, "infnfe.ide.tpnf") == "Entrada")
      return (
        this.getValueObject(
          this.modelConfig.infnfe.det.current,
          "prod.produto.usavalidadelote"
        ) === "Sim"
      );
    else return false;
  }

  currentProdutoUsaGrade() {
    return (
      this.getValueObject(
        this.modelConfig.infnfe.det.current,
        "prod.produto.usagrade1"
      ) === "Sim" ||
      this.getValueObject(
        this.modelConfig.infnfe.det.current,
        "prod.produto.usagrade2"
      ) === "Sim"
    );
  }

  get somaValoresAutomaticamente() {
    return this.model.impostocalculado === "Sim";
  }

  //***********Calculos dos produtos e totais ***********/

  calcTotalItem(tipo = undefined) {
    if (!this.somaValoresAutomaticamente) return null;
    let item = this.modelConfig.infnfe.det.current.prod;
    let vdescOld = item.vdesc;
    let vdesc = this.calc_vDescItem(item);
    if (tipo === "v") vdescOld = vdesc;
    if (item.vdesc !== vdesc) {
      if (tipo === "v") item.pdesc = this.calc_pDescItem(item);
      else item.vdesc = vdesc;
    }
    let vtotal =
      this.valueToFloat(item.qcom, 0) * this.valueToFloat(item.vuncom, 0);
    if (tipo == undefined)
      this.add_vProd(this.valueToFloat(item.vtotal, 0), vtotal);
    vtotal = vtotal - this.valueToFloat(item.vdesc, 0);
    this.add_vNF(this.valueToFloat(item.vtotal, 0), vtotal);
    item.vtotal = this.floatToValue(vtotal);
    this.add_vDescTotal(
      this.valueToFloat(vdescOld, 0),
      this.valueToFloat(item.vdesc, 0)
    );
  }

  calc_vDescItem(item) {
    if (!this.somaValoresAutomaticamente) return null;
    let vtotal =
      this.valueToFloat(item.qcom, 0) * this.valueToFloat(item.vuncom, 0);
    let vdesc = (vtotal * this.valueToFloat(item.pdesc, 0)) / 100;
    return this.floatToValue(vdesc);
  }

  calc_pDescItem(item) {
    if (!this.somaValoresAutomaticamente) return null;
    let vtotal =
      this.valueToFloat(item.qcom, 0) * this.valueToFloat(item.vuncom, 0);
    let vdesc = this.valueToFloat(item.vdesc, 0);
    let pdesc = (vdesc / vtotal) * 100;
    return this.floatToValue(pdesc);
  }

  add_vProd(oldValue: number, newValue: number) {
    if (!this.somaValoresAutomaticamente) return null;
    let vtotal = this.valueToFloat(this.model.infnfe.total.icmstot.vprod, 0);
    vtotal += newValue - oldValue;
    this.model.infnfe.total.icmstot.vprod = this.floatToValue(vtotal);
  }

  add_vNF(oldValue: number, newValue: number) {
    if (!this.somaValoresAutomaticamente) return null;
    let vtotal = this.valueToFloat(this.model.infnfe.total.icmstot.vnf, 0);
    vtotal += newValue - oldValue;
    this.model.infnfe.total.icmstot.vnf = this.floatToValue(vtotal);
    this.calcValorLiquidoDuplicatas();
  }

  calc_pDescTotal() {
    if (!this.somaValoresAutomaticamente) return null;
    let vprod = this.valueToFloat(this.model.infnfe.total.icmstot.vprod, 0);
    let vdesc = this.valueToFloat(this.model.infnfe.total.icmstot.vdesc, 0);
    let pdesc = (vdesc / vprod) * 100;
    return this.floatToValue(pdesc);
  }

  add_vDescTotal(oldValue: number, newValue: number) {
    if (!this.somaValoresAutomaticamente) return null;
    let vtotal = this.valueToFloat(this.model.infnfe.total.icmstot.vdesc, 0);
    vtotal += newValue - oldValue;
    this.model.infnfe.total.icmstot.vdesc = this.floatToValue(vtotal);
    this.model.infnfe.total.icmstot.pdesc = this.calc_pDescTotal();
  }

  calcValorLiquidoDuplicatas() {
    if (!this.somaValoresAutomaticamente) return null;
    let vliquido =
      this.valueToFloat(this.model.infnfe.total.icmstot.vnf, 0) -
      this.valueToFloat(this.model.infnfe.cobrpagar.vdesconto, 0);
    this.model.infnfe.cobrpagar.vliquido = this.floatToValue(vliquido);
  }

  onEnterDescTotal(tipo) {
    if (!this.somaValoresAutomaticamente) return null;
    let icmstot = this.model.infnfe.total.icmstot;
    icmstot["old_" + tipo + "desc"] = icmstot[tipo + "desc"];
  }

  calcDescTotal(tipo) {
    if (!this.somaValoresAutomaticamente) return null;
    let icmstot = this.model.infnfe.total.icmstot;
    let desc1 = "vdesc";
    let desc2 = "pdesc";
    if (tipo === "p") {
      desc1 = "pdesc";
      desc2 = "vdesc";
    }

    let vtotalprodutos = this.valueToFloat(icmstot.vprod, 0);
    let vdesconto = this.valueToFloat(icmstot.vdesc, 0);
    if (tipo === "p") {
      icmstot.vdesc = this.arredondar(
        (vtotalprodutos * this.valueToFloat(icmstot.pdesc, 0)) / 100
      );
      vdesconto = this.valueToFloat(icmstot.vdesc, 0);
    } else if (tipo === "v") {
      icmstot.pdesc = this.arredondar((vdesconto / vtotalprodutos) * 100);
    }
    icmstot.vnf = this.arredondar(vtotalprodutos - vdesconto);
  }

  onEnterItemDespesa(campo: string) {
    if (!this.somaValoresAutomaticamente) return null;
    let prod = this.modelConfig.infnfe.det.current.prod;
    prod["old_" + campo] = prod[campo];
  }

  calcTotalDespesa(campo: string) {
    if (!this.somaValoresAutomaticamente) return null;
    this.calcTotalImp(this.modelConfig.infnfe.det.current.prod, campo);
  }

  onEnterItemImp(item, campo: string) {
    if (!this.somaValoresAutomaticamente) return null;
    item["old_" + campo] = item[campo];
  }

  calcTotalImp(item, campo: string) {
    if (!this.somaValoresAutomaticamente) return null;
    this.add_vTotalImp(
      campo,
      this.valueToFloat(item["old_" + campo], 0),
      this.valueToFloat(item[campo], 0)
    );
  }

  add_vTotalImp(campo: string, oldValue: number, newValue: number) {
    if (!this.somaValoresAutomaticamente) return null;
    if (campo === "vicmsst") campo = "vst";
    let icmstot = this.model.infnfe.total.icmstot;
    let vtotal = this.valueToFloat(icmstot[campo], 0);
    vtotal += newValue - oldValue;
    icmstot[campo] = this.floatToValue(vtotal);
  }

  onRemoveItem(item) {
    if (!this.somaValoresAutomaticamente) return null;
    let vtotal =
      this.valueToFloat(item.prod.qcom, 0) *
      this.valueToFloat(item.prod.vuncom, 0);
    this.add_vProd(this.valueToFloat(vtotal, 0), 0);
    this.add_vNF(this.valueToFloat(item.prod.vtotal, 0), 0);
    this.add_vDescTotal(this.valueToFloat(item.prod.vdesc, 0), 0);

    this.add_vTotalImp("vfrete", this.valueToFloat(item.prod.vfrete, 0), 0);
    this.add_vTotalImp("vseg", this.valueToFloat(item.prod.vseg, 0), 0);
    this.add_vTotalImp("voutro", this.valueToFloat(item.prod.voutro, 0), 0);
    this.add_vTotalImp("vbc", this.valueToFloat(item.imposto.icms.vbc, 0), 0);
    this.add_vTotalImp(
      "vicms",
      this.valueToFloat(item.imposto.icms.vicms, 0),
      0
    );
    this.add_vTotalImp(
      "vbcst",
      this.valueToFloat(item.imposto.icmsst.vbcst, 0),
      0
    );
    this.add_vTotalImp(
      "vicmsst",
      this.valueToFloat(item.imposto.icmsst.vicmsst, 0),
      0
    );
    this.add_vTotalImp("vpis", this.valueToFloat(item.imposto.pis.vpis, 0), 0);
    this.add_vTotalImp(
      "vcofins",
      this.valueToFloat(item.imposto.cofins.vcofins, 0),
      0
    );
    this.add_vTotalImp(
      "vtottrib",
      this.valueToFloat(item.imposto.vtottrib, 0),
      0
    );
  }
}
