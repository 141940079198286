// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMFormRelatComponent, CMInputConfig,
         CMFormGlobaisComponent
       } from './../../../@cmcore/component';
import { AssociadoBrowseComponent } from '../../associado';

@Component({
    selector: 'ultimo-login',
    templateUrl: 'ultimo-login.component.html'
})
export class RelatUltimoLoginComponent extends CMFormRelatComponent {

  constructor(_injector: Injector) {
    super(_injector);
    this.relatName = 'ImpressaoUltimoLogin';
    this.filterConfig = {
      diassemacesso: new CMInputConfig({
        form: this,
        caption: 'Dias para considerar inativo',
        dataType: 'integer'
      }),
      status: new CMInputConfig({
        form: this,
        caption: "Status",
        values: [
          { value: "Ativo", text: "Ativo" },
          { value: "Inativo", text: "Inativo" },
          { value: "Todos", text: "Todos" },
        ],
      }),
      associado: new CMInputConfig({
        form: this,
        caption: "Associado",
        pesqFunc: (sender) => {
          AssociadoBrowseComponent.pesquisa(sender, this.filter, "Associado");
        },
      }),
    }
    this.filter.diassemacesso = '30';
    this.filter.status = 'Todos';
  }

}