// tslint:disable
import { Component, Injector } from "@angular/core";
import { ContaBancariaBrowseComponent } from "../conta-bancaria";
import { FormaPagamentoBrowseComponent } from "../formapagamento";

import {
  CMFormEditComponent,
  CMInputConfig,
  CMGridEditConfig,
  CMGridEditConfigColumn,
} from "./../../@cmcore/component";

@Component({
  selector: "duplicata-receber-edit",
  templateUrl: "duplicata-receber-edit.component.html",
})
export class DuplicataReceberEditComponent extends CMFormEditComponent {
  getTitle() {
    return "Duplicata a Receber - " + this.fieldByName("nduplicata");
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "duplicatareceber";
    this.setModelConfig();
  }

  afterGetModelData() {
    super.afterGetModelData();
    this.podeTerNovaBaixa = true;
    this.model.novabaixa = {};
    this.model.novabaixa.data = this.date;
    this.model.novabaixa.vmulta = this.floatToValue(0);
    this.model.novabaixa.vjuros = this.floatToValue(0);
    this.model.novabaixa.vdesconto = this.floatToValue(0);
    this.model.novabaixa.vdevido = this.model.vsaldo;
    this.model.novabaixa.vbaixa = this.floatToValue(0);
    this.model.novabaixa.observacao = "";
  }

  setModelConfig() {
    this.modelConfig = {
      id: new CMInputConfig({ form: this, caption: "#", disabled: true }),
      nduplicata: new CMInputConfig({
        form: this,
        caption: "Número da Duplicata",
        disabled: true,
      }),
      dvenc: new CMInputConfig({
        form: this,
        caption: "Data de Vencimento",
        disabled: true,
      }),
      valor: new CMInputConfig({
        form: this,
        caption: "Valor",
        disabled: true,
      }),
      vtotalmulta: new CMInputConfig({
        form: this,
        caption: "Valor total de multa",
        disabled: true,
      }),
      vtotaljuros: new CMInputConfig({
        form: this,
        caption: "Valor total de juros",
        disabled: true,
      }),
      vtotaldesconto: new CMInputConfig({
        form: this,
        caption: "Valor total de desconto",
        disabled: true,
      }),
      vtotaldevido: new CMInputConfig({
        form: this,
        caption: "Valor total devido",
        disabled: true,
      }),
      vtotalbaixa: new CMInputConfig({
        form: this,
        caption: "Valor total de baixa",
        disabled: true,
      }),
      vsaldo: new CMInputConfig({
        form: this,
        caption: "Valor do Saldo",
        disabled: true,
      }),
      databaixa: new CMInputConfig({
        form: this,
        caption: "Data de Baixa",
        disabled: true,
      }),
      observacao: new CMInputConfig({
        form: this,
        caption: "Observação",
        readonly: true,
      }),
      cnab: {
        codigobanco: new CMInputConfig({
          form: this,
          caption: "Código do Banco",
          disabled: true,
          maxlength: 3,
        }),
        agencia: new CMInputConfig({
          form: this,
          caption: "Agência",
          disabled: true,
          maxlength: 4,
        }),
        digitoagencia: new CMInputConfig({
          form: this,
          caption: "Dg",
          disabled: true,
          maxlength: 1,
        }),
        contacorrente: new CMInputConfig({
          form: this,
          caption: "Conta Corrente",
          disabled: true,
          maxlength: 10,
        }),
        digitocc: new CMInputConfig({
          form: this,
          caption: "Dg",
          disabled: true,
          maxlength: 1,
        }),
        carteira: new CMInputConfig({
          form: this,
          caption: "Carteira",
          disabled: true,
          maxlength: 1,
        }),

        modalidade: new CMInputConfig({
          form: this,
          caption: "Modalidade",
          disabled: true,
          maxlength: 2,
        }),
        nossonumero: new CMInputConfig({
          form: this,
          caption: "Nosso Número",
          disabled: true,
          maxlength: 9,
        }),
        linhadigitavel: new CMInputConfig({
          form: this,
          caption: "Linha Digitável",
          disabled: true,
          maxlength: 47,
        }),
        codigobarra: new CMInputConfig({
          form: this,
          caption: "Código de Barras",
          disabled: true,
          maxlength: 44,
        }),
        datageracao: new CMInputConfig({
          form: this,
          caption: "Data Geração",
          disabled: true,
          mask: "99/99/9999",
        }),
        dataexportacao: new CMInputConfig({
          form: this,
          caption: "Data Transmissão",
          disabled: true,
          mask: "99/99/9999",
        }),
      },
      contabancaria: new CMInputConfig({
        form: this,
        caption: "Conta Bancária",
        pesqFunc: (sender) => {
          ContaBancariaBrowseComponent.pesquisa(sender, this.model, "contabancaria");
          console.log('thismodel: ', this.model);
        },
      }),
      movimentos: new CMGridEditConfig({
        form: this,
        caption: "Movimentos",
        required: false,
        fields: {
          data: new CMInputConfig({
            form: this,
            caption: "Data",
            gridConfig: new CMGridEditConfigColumn(),
            disabled: true,
          }),
          vmulta: new CMInputConfig({
            form: this,
            caption: "Valor multa",
            gridConfig: new CMGridEditConfigColumn(),
            disabled: true,
          }),
          vjuros: new CMInputConfig({
            form: this,
            caption: "Valor juros",
            gridConfig: new CMGridEditConfigColumn(),
            disabled: true,
          }),
          vdesconto: new CMInputConfig({
            form: this,
            caption: "Valor desconto",
            gridConfig: new CMGridEditConfigColumn(),
            disabled: true,
          }),
          vbaixa: new CMInputConfig({
            form: this,
            caption: "Valor Baixa",
            gridConfig: new CMGridEditConfigColumn(),
            disabled: true,
          }),
          contabancaria: new CMInputConfig({
            form: this,
            caption: "Conta Bancaria",
            gridConfig: new CMGridEditConfigColumn({
              tipo: "input-pesquisa",
              getValue: (row) => {
                return this.getValueObject(row, "contabancaria.codigo");
              },
              getBottomLabelColumn: (row) => {
                return this.getValueObject(row, "contabancaria.descricao");
              },
            }),
            pesqFunc: (sender) => {
              ContaBancariaBrowseComponent.pesquisa(
                sender,
                this.modelConfig.movimentos.current,
                "contabancaria"
              );
            },
            disabled: true,
          }),
          observacao: new CMInputConfig({
            form: this,
            caption: "Observação",
            gridConfig: new CMGridEditConfigColumn(),
            disabled: true,
          }),
        },
      }),

      novabaixa: {
        data: new CMInputConfig({
          form: this,
          caption: "Data",
          mask: "99/99/9999",
        }),
        vmulta: new CMInputConfig({
          form: this,
          caption: "Valor multa",
          onExit: () => {
            this.calc_vDevido();
          },
          dataType: "double(2)",
        }),
        vjuros: new CMInputConfig({
          form: this,
          caption: "Valor juros",
          onExit: () => {
            this.calc_vDevido();
          },
          dataType: "double(2)",
        }),
        vdesconto: new CMInputConfig({
          form: this,
          caption: "Valor desconto",
          onExit: () => {
            this.calc_vDevido();
          },
          dataType: "double(2)",
        }),
        vdevido: new CMInputConfig({
          form: this,
          caption: "Valor devido",
          disabled: true,
        }),
        vbaixa: new CMInputConfig({
          form: this,
          caption: "Valor Baixa",
          dataType: "double(2)",
        }),
        observacao: new CMInputConfig({
          form: this,
          caption: "Observação",
          maxlength: 60,
        }),
        formapagamento: new CMInputConfig({
          form: this,
          caption: "Forma de pagamento",
          /*
          gridConfig: new CMGridEditConfigColumn({
            tipo: "select",
            onGetValue: (row) =>
              this.getValueObject(row, "formapagamento.id"),
            onSetValue: (row, value) => {
              this.modelConfig.novabaixa.formapagamento.setValueInObject(
                row,
                "formapagamento",
                value
              );
            },
          }),*/
          values: FormaPagamentoBrowseComponent.valuesSelect(this.http),
          valuesObject: FormaPagamentoBrowseComponent.valuesObjectSelect(),
        }),
      },
    };
  }

  podeTerNovaBaixa: boolean = true;

  onRemoveMovimento() {
    this.podeTerNovaBaixa = false;
    this.model.novabaixa.vbaixa = this.floatToValue(0);
  }

  calc_vDevido() {
    let vdevido = this.valueToFloat(this.model.vsaldo, 0);
    vdevido += this.valueToFloat(this.model.novabaixa.vmulta, 0);
    vdevido += this.valueToFloat(this.model.novabaixa.vjuros, 0);
    vdevido -= this.valueToFloat(this.model.novabaixa.vdesconto, 0);
    this.model.novabaixa.vdevido = this.floatToValue(vdevido);
  }

  customValid() {
    let r = super.customValid();
    if (this.podeTerNovaBaixa && r === "") {
      if (this.valueToFloat(this.model.novabaixa.vbaixa, 0) > 0) {
        if (
          this.valueToFloat(this.model.novabaixa.vbaixa, 0) >
          this.valueToFloat(this.model.novabaixa.vdevido, 0)
        )
          r = "Valor da baixa não pode ser maior que o valor dedivo";
      }
    }
    return r;
  }
}
