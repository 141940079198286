// tslint:disable
import {
  Injectable,
  OnInit,
  HostListener,
  NgZone,
  Injector,
  ComponentFactoryResolver,
  DoCheck,
} from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { NotificationsService } from "angular2-notifications";

import {
  CMInputComponent,
  CMInputConfig,
  CMGridConfig,
  CMGridEditConfig,
  CMListCheckBoxConfig,
  CMFormGlobaisComponent,
  CMFormModalComponent,
  CMMenuComponent,
} from "../../component";

@Injectable()
export abstract class CMFormComponent implements OnInit, DoCheck {
  configJSON: any = {};
  configJSONPath: string = "";

  static funcBasePathApi: Function = undefined;
  static get basePathApi() {
    if (CMFormComponent.funcBasePathApi)
      return CMFormComponent.funcBasePathApi();
    else return "";
  }

  public focusedComponent: Object = undefined;

  name: string = "cmform_" + CMInputComponent.nextGlobalId().toString();
  openModal() {
    CMFormModalComponent.openForm(this);
  }
  closeModal() {
    CMFormModalComponent.closeForm(this);
  }

  public disabledAll: boolean = undefined;

  protected get windowWidth() {
    return CMMenuComponent.windowWidth;
  }
  protected get windowHeight() {
    return CMMenuComponent.windowHeight;
  }

  protected usaMenuMobile: boolean = true;
  get inDesktop() {
    return CMMenuComponent.inDesktop(this.usaMenuMobile);
  }

  generateEmptyModel(config: any, obj: any) {
    CMFormComponent.generateEmptyModel(config, obj);
  }

  static generateEmptyModel(config: any, obj: any) {
    let props = Object.getOwnPropertyNames(config);
    for (var i = 0; i < props.length; ++i) {
      let prop = props[i];
      if (config[prop] instanceof CMInputConfig) {
        obj[prop] = "";
      } else if (config[prop] instanceof CMGridConfig) {
        let so = {};
        CMFormComponent.generateEmptyModel(config[prop].fields, so);
        obj[prop] = [so];
      } else if (config[prop] instanceof CMGridEditConfig) {
        obj[prop] = [];
      } else if (config[prop] instanceof CMListCheckBoxConfig) {
        obj[prop] = [];
      } else if (config[prop] instanceof Array) {
        obj[prop] = [];
      } else if (CMFormComponent.isBaseObject(config[prop])) {
        obj[prop] = {};
        CMFormComponent.generateEmptyModel(config[prop], obj[prop]);
      }
    }
  }

  static isBaseObject(_var: any) {
    return (
      Object.getPrototypeOf(_var).constructor.name.toLowerCase() === "object"
    );
  }

  post(url: string, body: any) {
    return CMFormComponent.post(this.http, url, body);
  }

  postSubscribe(
    url: string,
    body: any,
    next?: (value: Object) => void,
    error?: (error: any) => void,
    complete?: () => void
  ) {

    console.log(url + " " + body);
    CMFormComponent.postSubscribe(this.http, url, body, next, error, complete);
  }

  postSubscribeT<T>(
    url: string,
    body: any,
    next?: (value: T) => void,
    error?: (error: any) => void,
    complete?: () => void
  ) {
    CMFormComponent.postSubscribeT<T>(
      this.http,
      url,
      body,
      next,
      error,
      complete
    );
  }

  static postT<T>(
    http: HttpClient,
    url: string,
    body: any,
    responseType?: string
  ) {
    let _url = url.toLowerCase();
    if (_url.startsWith("/api/")) _url = CMFormComponent.basePathApi + _url;
    let headers = new HttpHeaders({
      Authorization: "Token " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    });
    let options = { headers: headers };
    if (responseType) options["responseType"] = responseType;
    return http.post<T>(_url, body, options);
  }

  static post(http: HttpClient, url: string, body: any, responseType?: string) {
    return CMFormComponent.postT<any>(http, url, body, responseType);
  }

  private static tryPostSubscribe<T>(
    tentativa: number,
    http: HttpClient,
    url: string,
    body: any,
    next?: (value: Object) => void,
    error?: (error: any) => void,
    complete?: () => void,
    responseType?: string
  ) {
    if (tentativa > 0)
      console.warn("Tentativa " + tentativa + " no endereço " + url);
    const s = CMFormComponent.postT<T>(http, url, body, responseType).subscribe(
      next,
      (e) => {
        if (!navigator.onLine || e.status === 0) {
          if (tentativa <= 4) {
            console.error(e);
            setTimeout(() => {
              CMFormComponent.tryPostSubscribe(
                tentativa + 1,
                http,
                url,
                body,
                next,
                error,
                complete,
                responseType
              );
            }, 5000);
          } else if (error) {
            error(e);
          }
        } else if (error) {
          error(e);
        }
      },
      () => {
        s.unsubscribe();
        if (complete) complete();
      }
    );
  }

  static postSubscribe(
    http: HttpClient,
    url: string,
    body: any,
    next?: (value: Object) => void,
    error?: (error: any) => void,
    complete?: () => void,
    responseType?: string
  ) {
    CMFormComponent.tryPostSubscribe<any>(
      0,
      http,
      url,
      body,
      next,
      error,
      complete,
      responseType
    );
  }

  // tslint:disable-next-line: member-ordering
  // tslint:disable-next-line: max-line-length
  // tslint:disable-next-line: member-ordering
  static postSubscribeT<T>(
    http: HttpClient,
    url: string,
    body: any,
    next?: (value: T) => void,
    error?: (error: any) => void,
    complete?: () => void,
    responseType?: string
  ) {
    CMFormComponent.tryPostSubscribe<any>(
      0,
      http,
      url,
      body,
      next,
      error,
      complete,
      responseType
    );
  }

  getValueObject(source: any, pathField: string) {
    return CMFormComponent.getValueObject(source, pathField);
  }

  static getValueObject(source: any, pathField: string) {
    let r: string = "";
    if (source && pathField !== "" && pathField !== undefined) {
      let paths = pathField.replace(/^[\s*.]+|[\s*.]+$/g, "").split(".");
      let obj = source;
      let prop = paths[paths.length - 1];
      for (let i = 0; i < paths.length - 1; i++) {
        if (obj[paths[i]] !== undefined) obj = obj[paths[i]];
        else {
          obj = undefined;
          break;
        }
      }
      if (obj !== undefined) r = obj[prop];
    }
    if (r === undefined || r === null) r = "";
    return r;
  }

  static setValueObject(source: any, pathField: string, value) {
    if (source && pathField !== "" && pathField !== undefined) {
      let paths = pathField.replace(/^[\s*.]+|[\s*.]+$/g, "").split(".");
      let obj = source;
      let prop = paths[paths.length - 1];
      for (let i = 0; i < paths.length - 1; i++) {
        if (obj[paths[i]] !== undefined) obj = obj[paths[i]];
        else {
          obj = undefined;
          break;
        }
      }
      if (obj !== undefined) obj[prop] = value;
    }
  }

  static valueIsEmpty(source: any, pathField: string) {
    let r: string = CMFormComponent.getValueObject(source, pathField);
    return r === "";
  }

  static trimValue(value: any) {
    return CMInputConfig.trimValue(value);
  }

  trimValue(value: any) {
    return CMInputConfig.trimValue(value);
  }

  getFormatError(origem: string, error: any) {
    console.error(error);
    let str: string = '<p class="divider"></p><div class="container">';
    if (origem !== "") str += "<p><b>Origem:</b> " + origem + "</p>";
    if (error.status !== undefined) {
      str += "<p><b>Status:</b> " + error.status + "</p>";
      if (error.status === 403) {
        localStorage.clear();
        let extras = {
          queryParams: {},
        };
        if (this.route.snapshot.queryParams) {
          Object.assign(extras.queryParams, this.route.snapshot.queryParams);
        }
        let rt = [];
        this.route.snapshot.url.forEach((element) => {
          rt.push(element.path);
        });
        if (rt.length > 0) {
          Object.assign(extras.queryParams, {
            routerok: rt.join("/"),
          });
        }
        this.router.navigate(["login"], extras);
        return undefined;
      }
    }
    if (error.statusText !== undefined)
      str += "<p><b>StatusText:</b> " + error.statusText + "</p>";
    if (error._body !== undefined)
      str += "<p><b>Body:</b> " + error._body + "</p>";
    str +=
      "<p><b>Detalhes:</b><pre>" +
      JSON.stringify(error, undefined, 2) +
      "</pre></p>";
    return str + '</div><p class="divider"></p>';
  }

  showMessageErrorHTTP(origem: string, error: any) {
    CMFormGlobaisComponent.showMessageError(this.getFormatError(origem, error));
  }

  protected injector: Injector;
  protected http: HttpClient;
  public router: Router;
  protected ngZone: NgZone;
  protected route: ActivatedRoute;
  protected resolver: ComponentFactoryResolver;
  protected notificationsService: NotificationsService;

  constructor(_injector: Injector) {
    this.injector = _injector;
    this.http = this.injector.get(HttpClient);
    this.router = this.injector.get(Router);
    this.ngZone = this.injector.get(NgZone);
    this.route = this.injector.get(ActivatedRoute);
    this.resolver = this.injector.get(ComponentFactoryResolver);
    this.notificationsService = this.injector.get(NotificationsService);
  }

  loadRoles() {
    let ro = localStorage.getItem("roles");
    if (ro) {
      try {
        this.roles = JSON.parse(ro);
      } catch (e) {
        this.roles = [];
      }
    }
  }

  ngDoCheck() {}

  ngOnInit() {
    if (this.configJSONPath != "") {
      let random = 1 + Math.random() * 100;
      this.http
        .get(
          CMFormComponent.basePathApi +
            "/api/" +
            this.configJSONPath +
            "?" +
            random.toString()
        )
        .subscribe((data) => {
          this.configJSON = data;
        });
    }
    this.loadRoles();

    let re = localStorage.getItem("recursos");
    if (re) {
      try {
        this.recursos = JSON.parse(re);
      } catch (e) {
        this.recursos = [];
      }
    }
    window.scrollBy(0, 0);
  }

  getObjectData(
    controllerName: string,
    filters = [],
    afterGetObjectData: Function
  ) {
    CMFormGlobaisComponent.showSpinner();
    this.postSubscribe(
      CMFormComponent.basePathApi + "/api/" + controllerName + "/getobject",
      JSON.stringify(filters),
      (obj) => {
        if (afterGetObjectData) afterGetObjectData(obj);
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        this.showMessageErrorHTTP("getObjectData", error);
      }
    );
  }

  get date() {
    return CMFormComponent.date();
  }

  static date() {
    return this.dateToValue(new Date());
  }

  static mes() {
    return CMFormComponent.valueToDate(CMFormComponent.date()).getMonth() + 1;
  }

  get mes() {
    return CMFormComponent.mes();
  }

  static incDate(days, date?: Date) {
    if (date === undefined)
      date = CMFormComponent.valueToDate(CMFormComponent.date());
    date.setDate(date.getDate() + days);
    return this.dateToValue(date);
  }

  incDate(days, date?: Date) {
    return CMFormComponent.incDate(days, date);
  }

  static decDate(days, date?: Date) {
    if (date === undefined)
      date = CMFormComponent.valueToDate(CMFormComponent.date());
    date.setDate(date.getDate() - days);
    return this.dateToValue(date);
  }

  decDate(days, date?: Date) {
    return CMFormComponent.decDate(days, date);
  }
  static firstDate(date?: Date) {
    if (date === undefined)
      date = CMFormComponent.valueToDate(CMFormComponent.date());
    return this.dateToValue(new Date(date.getFullYear(), date.getMonth(), 1));
  }

  firstDate(date?: Date) {
    return CMFormComponent.firstDate(date);
  }

  static firstDateLastMonth(date?: Date) {
    if (date === undefined)
      date = CMFormComponent.valueToDate(CMFormComponent.date());
    return this.dateToValue(
      new Date(date.getFullYear(), date.getMonth() - 1, 1)
    );
  }

  static firstDateNextMonth(date?: Date) {
    if (date === undefined)
      date = CMFormComponent.valueToDate(CMFormComponent.date());
    return this.dateToValue(
      new Date(date.getFullYear(), date.getMonth() + 1, 1)
    );
  }

  firstDateLastMonth(date?: Date) {
    return CMFormComponent.firstDateLastMonth(date);
  }

  firstDateNextMonth(date?: Date) {
    return CMFormComponent.firstDateNextMonth(date);
  }

  static lastDate(date?: Date) {
    if (date === undefined)
      date = CMFormComponent.valueToDate(CMFormComponent.date());
    return this.dateToValue(
      new Date(date.getFullYear(), date.getMonth() + 1, 0)
    );
  }

  lastDate(date?: Date) {
    return CMFormComponent.lastDate(date);
  }

  static lastDateNextMonth(date?: Date) {
    if (date === undefined)
      date = CMFormComponent.valueToDate(CMFormComponent.date());
    return this.dateToValue(
      new Date(date.getFullYear(), date.getMonth() + 2, 0)
    );
  }

  lastDateNextMonth(date?: Date) {
    return CMFormComponent.lastDateNextMonth(date);
  }

  relatAction(
    functionName: string,
    filter: Object,
    action: Function,
    controllerName?: string
  ) {
    if (controllerName === undefined) controllerName = "relats";
    CMFormGlobaisComponent.showSpinner();
    this.postSubscribeT<Array<string>>(
      CMFormComponent.basePathApi +
        "/api/" +
        controllerName +
        "/" +
        functionName,
      JSON.stringify(filter),
      (result) => {
        this.parseRelatAction(result, action, controllerName);
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        this.showMessageErrorHTTP("relatAction", error);
      }
    );
  }

  parseRelatAction(result, action: Function, controllerName?: string) {
    let result_str = result.pop();
    if (result_str === "ok") {
      let idArquivo = result.pop();
      let idArquivo_csv = "";
      if (result.length == 1) {
        idArquivo_csv = result.pop();
        if (idArquivo_csv !== "")
          idArquivo_csv =
            CMFormComponent.basePathApi +
            "/api/" +
            controllerName +
            "/getfilerelat/" +
            idArquivo_csv;
      }
      action(
        CMFormComponent.basePathApi +
          "/api/" +
          controllerName +
          "/getfilerelat/" +
          idArquivo,
        idArquivo_csv
      );
    } else {
      CMFormGlobaisComponent.toastErro(result_str);
    }
  }

  getRelat(functionName: string, filter: Object, newWindow: boolean = false) {
    this.relatAction(functionName, filter, (url) => {
      let targetWindow: string = "";
      if (!newWindow) targetWindow = "_self";
      window.open(url, targetWindow);
    });
  }

  showRelat(functionName: string, filter: Object, controllerName?: string) {
    this.relatAction(
      functionName,
      filter,
      (url, url_csv) => {
        if (CMFormComponent.isAndroid()) window.open(url, "_self");
        else
          window.open(url, "_blank");
      },
      controllerName
    );
  }

  static isMSIE() {
    return /(MSIE|Trident\/)/i.test(navigator.userAgent);
  }

  static isAndroid() {
    return /Android/i.test(navigator.userAgent);
  }

  static isiPad() {
    return /(iPad)/i.test(navigator.userAgent);
  }

  static isiPhone() {
    return /(iPhone)/i.test(navigator.userAgent);
  }

  static isMSIE_or_Edge() {
    return /(MSIE|Trident\/|Edge\/)/i.test(navigator.userAgent);
  }

  static isMobile() {
    return (
      CMFormComponent.isAndroid() ||
      CMFormComponent.isiPad() ||
      CMFormComponent.isiPhone()
    );
  }

  static isFireFox() {
    return /firefox/i.test(navigator.userAgent.toLowerCase());
  }

  static dateToValue(date: Date) {
    let d = date;
    let dia = d.getDate().toString();
    if (dia.length === 1) dia = "0" + dia;
    let mes = (d.getMonth() + 1).toString();
    if (mes.length === 1) mes = "0" + mes;
    let ano = d.getFullYear().toString();
    let r = dia + "/" + mes + "/" + ano;
    return r;
  }

  dateToValue(date: Date) {
    return CMFormComponent.dateToValue(date);
  }

  formatarDataExtenso(data, diaDaSemana: boolean = false) {
    return CMFormComponent.formatarDataExtenso(data, diaDaSemana);
  }

  static formatarDataExtenso(data, diaDaSemana: boolean = false) {
    // Meses possíveis
    var meses = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];
    // Dias possíveis
    var diasSemana = [
      "Domingo",
      "Segunda-feira",
      "Terça-feira",
      "Quarta-feira",
      "Quinta-feira",
      "Sexta-feira",
      "Sábado",
    ];
    // Partes da data informada
    var dia = data.getDate();
    var dias = data.getDay();
    var mes = data.getMonth();
    var ano = data.getYear();
    // Resultado
    var extenso = "";

    // Para o navegador Netscape o ano começa em 1900
    if (navigator.appName == "Netscape") {
      ano = ano + 1900;
    }

    if (dia.toString().length === 1) dia = "0" + dia;

    if (diaDaSemana) extenso += diasSemana[dias] + ", ";
    extenso += dia + " de " + meses[mes] + " de " + ano;

    return extenso;
  }

  numeroExtenso(c): string {
    return CMFormComponent.numeroExtenso(c);
  }

  static numeroExtenso(c): string {
    return c;
  }

  static valueToDate(date: string) {
    let d = ["0", "0", "0"];
    if (date !== undefined && date.split !== undefined) d = date.split("/");
    let dia = CMInputConfig.valueToInteger(d[0], 0);
    let mes = CMInputConfig.valueToInteger(d[1], 0) - 1;
    let ano = CMInputConfig.valueToInteger(d[2], 0);
    let r = new Date(ano, mes, dia);
    return r;
  }

  valueToDate(date: string) {
    return CMFormComponent.valueToDate(date);
  }

  static currentUser() {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      return {
        id: user["id"],
        nome: user["nome"],
        ownerid: user["ownerid"],
      };
    } else {
      return {
        id: 0,
        nome: "",
        ownerid: 0,
      };
    }
  }

  get currentUser() {
    return CMFormComponent.currentUser();
  }

  get currentUserStorage() {
    return CMFormComponent.currentUserStorage();
  }

  static currentUserStorage() {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) return user;
    else return {};
  }

  private roles = [];
  isRole(role: string) {
    if (this.roles.length === 0) {
      this.loadRoles();
    }
    return this.roles.indexOf(role) > -1;
  }

  get isLogged() {
    let r: boolean = false;
    if (localStorage.getItem("currentUser")) r = true;
    if (r) {
      r = false;
      if (localStorage.getItem("token")) r = true;
    }
    return r;
  }

  protected recursos = [];
  isRecurso(recurso: number) {
    let s = recurso + "=true";
    return this.recursos.indexOf(s) > -1;
  }

  isAnyRecurso(recursos: number[]) {
    let r = false;
    for (let i = 0; i < recursos.length; i++) {
      r = this.isRecurso(recursos[i]);
      if (r) break;
    }
    return r;
  }

  isEmpty(value: any) {
    return CMInputConfig.trimValue(value) === "";
  }

  valueToInteger(value: any, _default?: number) {
    return CMInputConfig.valueToInteger(value, _default);
  }

  integerToValue(integer: number) {
    return CMInputConfig.integerToValue(integer);
  }

  valueToFloat(value: any, _default?: number) {
    return CMInputConfig.valueToFloat(value, _default);
  }

  floatToValue(float: number, casasDecimais?: number) {
    return CMInputConfig.floatToValue(float, casasDecimais);
  }

  arredondar(value: number, casasDecimais?: number) {
    return CMInputConfig.arredondar(value, casasDecimais);
  }

  protected getKeyDownCodes() {
    return [];
  }

  @HostListener("window:keydown", ["$event"])
  public _onKeydown(event: KeyboardEvent) {
    let ok: boolean = true;
    if (event.keyCode === 13) {
      if (document.activeElement) {
        ok = document.activeElement.tagName.toLowerCase() !== "textarea";
      }
    }
    if (ok) {
      let keyCode = this.getKeyDownCodes().find((no) => no === event.keyCode);
      if (keyCode) event.preventDefault();
      this.onKeydown(keyCode, event);
    }
  }

  protected onKeydown(keyCode: number, event: KeyboardEvent) {}

  getCacheDireitos(controllerName: string, complemento?: string) {
    if (complemento === undefined) complemento = "Direct";
    if (complemento === "Direct") complemento = "";
    let s = localStorage.getItem(controllerName + complemento + "1cd");
    return JSON.parse(s);
  }

  setCacheDireitos(data: Object, controllerName: string, complemento?: string) {
    if (complemento === undefined) complemento = "Direct";
    if (complemento === "Direct") complemento = "";
    localStorage.setItem(
      controllerName + complemento + "1cd",
      JSON.stringify(data)
    );
  }

  static clearCacheAllDireitos() {
    let apagar = [];
    for (let index = 0; index < localStorage.length; index++) {
      const key: string = localStorage.key(index);
      if (key.endsWith("1cd")) {
        const item = localStorage.getItem(key);
        if (item && item.indexOf("Cadastrar") !== 1) apagar.push(key);
      }
    }
    for (const key in apagar) {
      localStorage.removeItem(apagar[key]);
    }
  }

  getDireitos(
    controllerName: string,
    tipo?: string,
    funcname?: string,
    callBack?: Function
  ) {
    if (tipo === undefined) tipo = "Direct";
    let complemento = "";
    if (funcname !== undefined) complemento = "." + funcname;
    let cacheData = this.getCacheDireitos(controllerName, tipo + complemento);
    if (!cacheData) {
      this.postSubscribe(
        CMFormComponent.basePathApi +
          "/api/" +
          controllerName.toLowerCase() +
          "/getdireitos",
        JSON.stringify({
          hash: "ClassName" + complemento,
          tipo: tipo,
        }),
        (data) => {
          this.setCacheDireitos(data, controllerName, tipo + complemento);
          if (callBack) callBack(data);
        },
        (error) => {
          this.showMessageErrorHTTP("getDireitos", error);
        }
      );
    } else {
      if (callBack) callBack(cacheData);
    }
  }

  // usado para converter uma string UTF-8 em ANSI
  stringToUint8Array(str: String): Uint8Array {
    const uint8 = new Uint8Array(str.length);
    for (let i = 0; i < uint8.length; i++) uint8[i] = str.charCodeAt(i);
    return uint8;
  }

  downloadContent(content, filename, contentType = "text/csv;charset=utf-8;") {
    var blob = new Blob([content], { type: contentType });
    if (navigator.msSaveBlob)
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    //create a link and click it
    else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  downloadTmpFile(
    controllerName,
    functionPrepareFile,
    dataPrepareFile,
    target = "_self"
  ) {
    CMFormGlobaisComponent.showSpinner();

    this.postSubscribe(
      "/api/" + controllerName + "/" + functionPrepareFile + "/FuncaoExtra",
      JSON.stringify(dataPrepareFile),
      (r) => {
        if (r["result"] === "ok") {
          let file = r["file"];
          let userid = r["chave"];
          window.open(
            "/api/" +
              controllerName +
              "/GetTmpFile/" +
              userid +
              "/" +
              file +
              "/" +
              target,
            target
          );
        } else {
          CMFormGlobaisComponent.toastErro(r["result"]);
        }
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        this.showMessageErrorHTTP("downloadTmpFile", error);
      }
    );
  }

  showTmpFile(controllerName, functionPrepareFile, dataPrepareFile) {
    CMFormGlobaisComponent.showSpinner();
    this.postSubscribe(
      "/api/" + controllerName + "/" + functionPrepareFile + "/FuncaoExtra",
      JSON.stringify(dataPrepareFile),
      (r) => {
        if (r["result"] === "ok") {
          let file = r["file"];
          let userid = r["chave"];
          let url =
            "/api/" + controllerName + "/GetTmpFileShow/" + userid + "/" + file;
          CMFormGlobaisComponent.showMessageURL(url, undefined, false);
        } else {
          CMFormGlobaisComponent.toastErro(r["result"]);
        }
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        this.showMessageErrorHTTP("downloadTmpFile", error);
      }
    );
  }

  validField(config: CMInputConfig, value: any, row, parentCaption) {
    let result = "";
    if (config && config.components && config.components.length > 0) {
      result = config.valid(value, false, row);
      if (result !== "") {
        let c: CMInputComponent = config.components[0];
        c.focus();
        c.checkCustomValidity();
        let tmp = "";
        if (this.trimValue(parentCaption) !== "") {
          tmp = parentCaption + " - ";
        }
        CMFormGlobaisComponent.toastErro(result, tmp + c.getCaptionText());
      }
    }
    return result === "";
  }

  private internalValid(config: any, obj: any, parentCaption) {
    let props = Object.getOwnPropertyNames(config);
    let result: boolean = true;
    for (var i = 0; i < props.length; ++i) {
      let prop = props[i];
      if (config[prop] instanceof CMInputConfig) {
        if (obj && obj[prop])
          result = this.validField(config[prop], obj[prop], obj, parentCaption);
        else
          result = this.validField(
            config[prop],
            undefined,
            undefined,
            parentCaption
          );
        if (!result) break;
      } else if (config[prop] instanceof CMGridConfig) {
        let gridConfig: CMGridConfig = config[prop];
        let objName = prop;
        if (gridConfig.objName !== "") objName = gridConfig.objName;
        let arr = obj[objName];
        if (arr instanceof Array) {
          if (gridConfig.required && arr.length === 0) {
            result = false;
            CMFormGlobaisComponent.toastErro(
              "ao menos um item deve ser informado",
              gridConfig.caption
            );
          } else {
            for (var j = 0; j < arr.length; ++j) {
              let item = arr[j];
              if (gridConfig.grid)
                gridConfig.grid.selectIndex(item.$$index, false);
              result = this.internalValid(
                gridConfig.fields,
                item,
                gridConfig.caption
              );
              if (result) result = gridConfig.validItem(item);
              if (!result) break;
            }
          }
          if (!result) break;
        } else if (gridConfig.required) {
          result = false;
          CMFormGlobaisComponent.toastErro(
            "ao menos um item deve ser informado",
            gridConfig.caption
          );
          break;
        }
      } else if (config[prop] instanceof CMGridEditConfig) {
        let gridConfig: CMGridEditConfig = config[prop];
        let objName = prop;
        if (gridConfig.objName !== "") objName = gridConfig.objName;
        let arr = obj[objName];
        if (arr instanceof Array) {
          if (gridConfig.required && arr.length === 0) {
            result = false;
            CMFormGlobaisComponent.toastErro(
              "ao menos um item deve ser informado",
              gridConfig.caption
            );
          } else {
            for (var j = 0; j < arr.length; ++j) {
              let item = arr[j];
              if (gridConfig.grid) gridConfig.grid.select(item, false);
              result = this.internalValid(
                gridConfig.fields,
                item,
                gridConfig.caption
              );
              if (result) result = gridConfig.validItem(item);
              if (!result) break;
            }
          }
          if (!result) break;
        } else if (gridConfig.required) {
          result = false;
          CMFormGlobaisComponent.toastErro(
            "ao menos um item deve ser informado",
            gridConfig.caption
          );
          break;
        }
      } else if (config[prop] instanceof CMListCheckBoxConfig) {
        let listCheckBoxConfig: CMListCheckBoxConfig = config[prop];
        let arr = obj[prop];
        if (arr instanceof Array) {
          if (listCheckBoxConfig.required && arr.length === 0) {
            result = false;
            CMFormGlobaisComponent.toastErro(
              "ao menos um item deve ser selecionado",
              listCheckBoxConfig.caption
            );
            break;
          }
        }
      } else if (CMFormComponent.isBaseObject(config[prop])) {
        result = this.internalValid(config[prop], obj[prop], "");
        if (!result) break;
      }
    }
    return result;
  }

  unSelectItens(config: any) {
    let props = Object.getOwnPropertyNames(config);
    for (var i = 0; i < props.length; ++i) {
      let prop = props[i];
      if (config[prop] instanceof CMGridConfig) {
        let gridConfig: CMGridConfig = config[prop];
        gridConfig.unSelect();
      } else if (config[prop] instanceof CMGridEditConfig) {
        let gridConfig: CMGridEditConfig = config[prop];
        gridConfig.unSelect();
      } else if (CMFormComponent.isBaseObject(config[prop]))
        this.unSelectItens(config[prop]);
    }
  }

  customValid(modelConfig) {
    this.unSelectItens(modelConfig);
    return "";
  }

  beforeValid() {
    return "";
  }

  valid(modelConfig, model) {
    let r = true;
    let bv: string = this.beforeValid();
    if (CMInputConfig.trimValue(bv) !== "") {
      CMFormGlobaisComponent.toastErro(bv);
      r = false;
    }
    if (r) {
      r = this.internalValid(modelConfig, model, "");
      if (r) {
        let cv: string = this.customValid(modelConfig);
        if (CMInputConfig.trimValue(cv) !== "") {
          CMFormGlobaisComponent.toastErro(cv);
          r = false;
        }
      }
    }
    return r;
  }

  openNewURL(url: string, target?: string) {
    if (!target) target = "_blank";
    setTimeout(function () {
      let w = window.open(url, target);
      if (w) {
        if (w.document) w.document.close();
        w.focus();
      }
    }, 10);
  }

  static _loadCSS_Color: boolean = false;
  static loadCSS_Color() {
    if (!CMFormComponent._loadCSS_Color) {
      let styleColor: string = CMFormComponent.trimValue(
        CMFormComponent.currentUserStorage().styleColor
      );
      if (styleColor) {
        CMFormComponent._loadCSS_Color = true;
        let cn = document.getElementsByTagName("head").item(0).childNodes;
        for (let index = 0; index < cn.length; index++) {
          const element = cn.item(index);
          let link: any = element;
          if (link.href) {
            let href: string = link.href;
            if (href.endsWith(".bundle.css"))
              link.href = href.replace(
                ".bundle.css",
                ".bundle." + styleColor + ".css"
              );
          }
        }
      }
    }
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
}
