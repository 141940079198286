// tslint:disable
import { Injector, Component } from "@angular/core";
import { CMFormComponent } from "../../@cmcore/component/cmform/cmform.component";
import { FornecedorBrowseComponent } from "../fornecedor";
import { FuncsGlobais } from "../funcs.globais";
import { ProdutoDetalhesSaldoComponent } from "../produto-detalhes-saldo";
import { ProdutoRelatoriosComponent } from "../produto-relatorios";

import {
  CMFormBrowse2Component,
  CMFormPesquisaComponent,
  CMInputConfig,
  CMInputComponent,
  CMFormModalComponent,
} from "./../../@cmcore/component";
import { ProdutoEditComponent } from "./produto-edit.component";

@Component({
  selector: "produto-browse",
  templateUrl:
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html",
  styleUrls: [
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss",
  ],
})
export class ProdutoBrowseComponent extends CMFormBrowse2Component {
  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "produto";
    this.fixedFooterFormEdit = false;
    this.showPrintButton = true;
    this.buttonsExtra = 3;
    this.formEditComponent = ProdutoEditComponent;
    this.columns = [
      {
        field: "codigo",
        caption: "Código",
        filter: new CMInputConfig({
          maxlength: 30,
          padInfo: { size: 30 },
        }),
      },
      {
        field: "descr",
        caption: "Descrição",
        filter: new CMInputConfig({
          maxlength: 60,
        }),
      },
      {
        field: "ean",
        caption: "EAN",
        filter: new CMInputConfig({
          maxlength: 14,
        }),
      },
      {
        field: "codigofabricante",
        caption: "Código do Fabricante",
        filter: new CMInputConfig({
          maxlength: 60,
        }),
      },
      {
        field: "saldo",
        caption: "Saldo",
        icon:
          FuncsGlobais.paramEmpresa.modoOperacao === "Nivel1"
            ? undefined
            : "list",
        iconHint: "Detalhes do Saldo",
        textAlign: "right",
        iconClick: (item, column) => this.detalhesSaldo(item, column),
      },
      {
        field: "fornecedor",
        caption: "Fornecedor",
        filterField: "fornecedor.id",
        filterValue: "fornecedor.codigo",
        fieldBottom: "fornecedor.nome",
        visible: false,
        filter: new CMInputConfig({
          dataType: "integer",
          pesqFunc: (sender) => {
            FornecedorBrowseComponent.pesquisa(sender, this.valuesFilters);
          },
        }),
      },
      {
        field: "ativo",
        caption: "Status",
        filter: new CMInputConfig({
          values: [
            { value: "Ativos" },
            { value: "Inativos" },
            { value: "Todos" },
          ],
        }),
        value: "Ativos",
      },
    ];
    if (ProdutoRelatoriosComponent.getRelatorioCustomizados().length === 0){
      this.getRelatoriosCustomizados();
    }
    console.log('Relatrios: ', ProdutoRelatoriosComponent.getRelatorioCustomizados());
  }

  getRelatoriosCustomizados(){
    this.postSubscribeT<Array<Object>>(
      "/api/produto/GetRelatoriosCustomizados",
      JSON.stringify({}),
      (result) => {
        result.forEach(element => {
          if (element['tipo'] === 'EtiquetaProduto'){
            ProdutoRelatoriosComponent.addRelatorioCustomizado({value: element['id'], text: element['descricao']});
          }
        });
      },
    );
  }

  getTitle() {
    return "Produto";
  }

  loadDireitoButtonExtra(index: number) {
    if (index === 0) {
      this.getDireitosMenuBrowseButtonsExtra(index, "PrintPadraoOperacao");
    } else if (index === 1 || index === 2) {
      this.getDireitosMenuBrowseButtonsExtra(index, "PrintListagemProdutos");
    }
  }

  buttonsExtraVisible(index: number) {
    let r: boolean = super.buttonsExtraVisible(index);
    if (r) {
      let isCurrent: boolean = false;
      if (this.grid) {
        isCurrent = this.grid.isCurrent;
      }
      if (index === 0 || index === 1) r = isCurrent;
    }
    return r;
  }

  buttonsExtraIcon(index: number) {
    let r: string = "";
    if (index === 0) r = "poll";
    else if (index === 1) r = "shopping_basket";
    else if (index === 2) r = "print";
    return r;
  }

  buttonsExtraHint(index: number) {
    let r: string = "";
    if (index === 0) r = "Imprime Padrão de Operação";
    else if (index === 1) r = "Imprime Listagem de Produto";
    else if (index === 2) r = "Imprime Etiquetas com base em lista de produtos por filtro";
    return r;
  }
  
  buttonsExtraPosicao(index: number) {
    let r: string = super.buttonsExtraPosicao(index);
    if (index === 2) r = "grid";
    return r;
  }

  buttonsExtraClick(index: number) {
    if (index === 0) {
      let data = { id: this.grid.fieldByName(this.fieldNameId()) };
      data = this.getDataPrint(data);
      this.showRelat(
        "PrintPadraoOperacao/FuncaoExtra",
        data,
        this.controllerName
      );
    } else if (index === 1) {
      let data = { id: this.grid.fieldByName(this.fieldNameId()) };
      data = this.getDataPrint(data);
      this.showRelat(
        "PrintListagemProdutos/FuncaoExtra",
        data,
        this.controllerName
      );
    } else if (index === 2) {

      let pessoa;
      //console.log(this.owner.valuesFilters);

      if (this.valuesFilters.hasOwnProperty("fornecedor")){
      pessoa = this.valuesFilters.fornecedor.id;
      console.log('tilro ', pessoa);
      }
      else {
      pessoa = 0;
      }
      
      if (ProdutoRelatoriosComponent.getRelatorioCustomizados().length <= 1 ){
        this.showRelat(
        "ImprimeEtiquetas/FuncaoExtra",
        {
            listaetiqueta: 2,
            pessoa,
            codigoproduto: this.valuesFilters.codigo,
            codigofabricante: this.valuesFilters.codigofabricante,
            descricao: this.valuesFilters.descr,
            ean: this.valuesFilters.ean
        },
        "produto"
        );
      } else {
        ProdutoRelatoriosComponent.isLista = true;
        this.openFormRelatoriosNFeRecebimento();
      }
      
      /*
      this.beforeComponent = ProdutoAcoesComponent;

      setTimeout(() => {
        CMFormModalComponent.open("formAcoesProdutoImprimirEtiquetas");
      }, 100);*/
    }
  }

  
  openFormRelatoriosNFeRecebimento() {
    this.beforeComponent = ProdutoRelatoriosComponent;
    setTimeout(() => {
      CMFormModalComponent.open("formRelatoriosProduto");
    }, 100);
  }

  detalhesSaldo(item, column) {
    this.beforeComponent = ProdutoDetalhesSaldoComponent;
    CMFormModalComponent.open("formDetalhesSaldo");
  }

  static dropdownValuesVUnCom(produto) {
    let r = [];
    if (produto && produto.precos) {
      produto.precos.forEach((preco) => {
        if (CMInputConfig.valueToFloat(preco.vuncom) > 0) {
          r.push({
            text: preco.empresapreco.nome + ": " + preco.vuncom,
            value: preco.vuncom,
          });
        }
      });
    }
    if (produto && produto.precospromocao) {
      produto.precospromocao.forEach((preco) => {
        if (CMInputConfig.valueToFloat(preco.vuncom) > 0) {
          if (
            this.valueToDate(preco.datainicio) <=
              this.valueToDate(CMFormComponent.date()) &&
            this.valueToDate(preco.datatermino) >=
              this.valueToDate(CMFormComponent.date())
          ) {
            r.push({
              text: preco.nome + ": " + preco.vuncom,
              value: preco.vuncom,
            });
          }
        }
      });
    }
    return r;
  }

  static get_vUniCon(produto) {
    let r = "0,00";
    if (produto) {
      if (produto.precos) {
        if (produto.precos.length > 0) r = produto.precos[0].vuncom;
      }
      if (produto.precospromocao) {
        for (let index = 0; index < produto.precospromocao.length; index++) {
          const preco = produto.precospromocao[index];
          if (
            this.valueToDate(preco.datainicio) <=
              this.valueToDate(CMFormComponent.date()) &&
            this.valueToDate(preco.datatermino) >=
              this.valueToDate(CMFormComponent.date())
          ) {
            r = preco.vuncom;
            break;
          }
        }
      }
    }
    return r;
  }

  static pesquisa(
    sender: any,
    target: any,
    ativo: string,
    targetProp?: string,
    onAfterSelect?: Function
  ) {
    if (target) {
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        "produto",
        "Pesquisa de Produtos",
        [
          {
            field: "codigo",
            caption: "Código",
            filter: new CMInputConfig({
              maxlength: 30,
              padInfo: { size: 30 },
            }),
          },
          { field: "descr", caption: "Descrição", filter: true },
          {
            field: "ean",
            caption: "EAN",
            filter: new CMInputConfig({
              maxlength: 14,
            }),
          },
          {
            field: "codigofabricante",
            caption: "Código do Fabricante",
            filter: new CMInputConfig({
              maxlength: 60,
            }),
          },
          { field: "saldo", caption: "Saldo" },
          { field: "observacao", caption: "Observação" },
          {
            field: "ativo",
            caption: "Status",
            dataType: "select",
            filter: new CMInputConfig({
              values: [
                { value: "Ativos" },
                { value: "Inativos" },
                { value: "Todos" },
              ],
            }),
            value: ativo,
          },
        ],
        target,
        targetProp,
        "codigo",
        onAfterSelect,
        false,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        ["CodigoFabricante", "EAN"]
      );
    }
  }

  static pesquisaGrade(
    sender: any,
    target: any,
    produto: string,
    targetProp?: string,
    onAfterSelect?: Function
  ) {
    if (targetProp == undefined) targetProp = "grade";
    if (target) {
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        "produtograde",
        "Pesquisa de Grade",
        [
          {
            field: "$idproduto",
            caption: "Produto",
            filter: true,
            filterVisible: false,
            visible: false,
            value: produto,
          },
          { field: "descricao", caption: "Grade", filter: true },
          { field: "saldo", caption: "Saldo" },
        ],
        target,
        targetProp,
        undefined,
        onAfterSelect
      );
    }
  }

  
  static pesquisaLoteOnSelect(
    sender: any,
    target: any,
    produto: string,
    grade: string,
    targetProp?: string,
    onAfterSelect?: Function
  ) {
    if (targetProp == undefined) targetProp = "numerolote";
    if (target) {
      CMFormPesquisaComponent.defaultPesquisaOnSelect(
        sender,
        "ProdutoEstoque",
        "Pesquisa de Lote",
        [
          {
            field: "$idproduto",
            caption: "Produto",
            filter: true,
            filterVisible: false,
            visible: false,
            value: produto,
          },
          {
            field: "$idgrade",
            caption: "Grade",
            filter: true,
            filterVisible: false,
            visible: false,
            value: grade,
          },
          { field: "numerolote", caption: "Número do Lote", filter: true },
          { field: "datavalidadelote", caption: "Data de Validade" },
          { field: "saldo", caption: "Saldo" },
        ],
        target,
        targetProp,
        "numerolote",
        onAfterSelect,
        false,
        undefined,
        undefined,
        (row, targetObj, targetProp, field, onAfterSelect, fieldInteger) => {
          if (sender === undefined) {
            targetObj[targetProp] = row[field];
          } else {
            let input: CMInputComponent = <CMInputComponent>sender;
            targetObj[targetProp] = input.value;
          }
        }
      );
    }
  }

  
  static pesquisaLote(
    sender: any,
    target: any,
    produto: string,
    grade: string,
    targetProp?: string,
    onAfterSelect?: Function
  ) {
    if (targetProp == undefined) targetProp = "numerolote";
    if (target) {
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        "ProdutoEstoque",
        "Pesquisa de Lote",
        [
          {
            field: "$idproduto",
            caption: "Produto",
            filter: true,
            filterVisible: false,
            visible: false,
            value: produto,
          },
          {
            field: "$idgrade",
            caption: "Grade",
            filter: true,
            filterVisible: false,
            visible: false,
            value: grade,
          },
          { field: "numerolote", caption: "Número do Lote", filter: true },
          { field: "datavalidadelote", caption: "Data de Validade" },
          { field: "saldo", caption: "Saldo" },
        ],
        target,
        targetProp,
        undefined,
        onAfterSelect
      );
    }
  }
}
