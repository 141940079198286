// tslint:disable
import { Injector, Component } from '@angular/core';

import { CMFormBrowse2Component, CMFormPesquisaComponent } from './../../@cmcore/component';
import { SeguimentoEditComponent } from './seguimento-edit.component';

@Component({
  selector: 'seguimento-browse',
  templateUrl: './../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html',
  styleUrls: ['./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss']
})
export class SeguimentoBrowseComponent extends CMFormBrowse2Component {

  static defaultColumns = [{field: 'codigo', caption: '#',  width: '100'},
                           {field: 'descricao', caption: 'Descrição', filter: true}];

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'seguimento';
    this.fixedFooterFormEdit = false;
    this.formEditComponent = SeguimentoEditComponent;
    this.columns = SeguimentoBrowseComponent.defaultColumns;
  }

  getTitle() {
    return 'Seguimento';
  }

  static pesquisaSeguimento(sender: any, target: any) {
    if (target) {
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        'seguimento',
        'Pesquisa de Seguimento',
        SeguimentoBrowseComponent.defaultColumns,
        target,
        undefined,
        'codigo',
      );
    }
  }

}