// tslint:disable
import { Component, Injector } from "@angular/core";

import {
  CMFormEditComponent,
  CMInputConfig,
  CMFormGlobaisComponent,
  CMGridEditConfig,
  CMGridEditConfigColumn,
  CMListCheckBoxConfig,
  CMGridComponent,
} from "./../../@cmcore/component";

import {
  PadraoDeOperacaoBrowseComponent,
  CESTBrowseComponent,
  GradeBrowseComponent,
  IBPTBrowseComponent,
  GrupoDeProdutoBrowseComponent,
  SubGrupoDeProdutoBrowseComponent,
  ProdutoBrowseComponent,
} from "./../index";
import { FuncsGlobais } from "../funcs.globais";
import { PessoaBrowseComponent } from "../pessoa";

@Component({
  selector: "produto-edit",
  templateUrl: "produto-edit.component.html",
  styleUrls: ["produto-edit.component.scss"],
})
export class ProdutoEditComponent extends CMFormEditComponent {
  getFirstComponentName() {
    return "model_codigo";
  }

  getTitle() {
    return "Produto - " + this.fieldByName("descr");
  }

  podeVisualizarInformacoesFornecedores: boolean = false;
  podeVisualizarFormacaoPrecoVenda: boolean = false;
  adicionouNovaGrade: boolean = false;


  gradestamanhos = [];
  gradescores = [];
  gradesconfig = [];

  configGrades = {
    adicionanovotamanho: new CMInputConfig({
      form: this,
      caption: "Adicionar novo tamanho",
      maxlength: 60,
      addFunc: () => {
        if ((this.dadosGrades.adicionanovotamanho !== "") && !(this.gradeExiste(this.dadosGrades.adicionanovotamanho))) {
          this.saveNovaGrade(GradeBrowseComponent, this.dadosGrades.adicionanovotamanho, "1");
          this.dadosGrades.adicionanovotamanho = "";
        }
      },
    }),
    adicionanovacor: new CMInputConfig({
      form: this,
      caption: "Adicionar nova cor",
      maxlength: 60,
      addFunc: () => {
        if ((this.dadosGrades.adicionanovacor !== "") && !(this.gradeExiste(this.dadosGrades.adicionanovacor))) {
          this.saveNovaGrade(GradeBrowseComponent, this.dadosGrades.adicionanovacor, "2");
          this.dadosGrades.adicionanovacor = "";
        }
      },
    }),
  }

  dadosGrades = {
    adicionanovotamanho: "",
    adicionanovacor: ""
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "produto";
    this.setModelConfig();
    this.getDireitos(
      this.controllerName,
      "Extra",
      "PodeVisualizarInformacoesFornecedores",
      (data) => {
        this.podeVisualizarInformacoesFornecedores =
          data["Executar"] === "true";
      }
    );
    this.getDireitos(
      this.controllerName,
      "Extra",
      "PodeVisualizarFormacaoPrecoVenda",
      (data) => {
        this.podeVisualizarFormacaoPrecoVenda = data["Executar"] === "true";
      }
    );
    if(GradeBrowseComponent.getGrades().length === 0) {
      GradeBrowseComponent.carregaGrades(this.http, "1", (dados) => {
        dados.forEach(element => {
          if((element['indice'] === "1") && (element['codigo'] !== "GRA1_ND")){
            this.gradestamanhos.push(element);
          }else if((element['indice'] === "2") && (element['codigo'] !== "GRA2_ND")){
            this.gradescores.push(element);
          }
        });
        console.log('gradestamanhos: ', this.gradestamanhos);
        console.log('gradescores: ', this.gradescores);
      });
      this.adicionouNovaGrade = false;
    }
    else {
      console.log(GradeBrowseComponent.getGrades());
      GradeBrowseComponent.getGrades().forEach(element => {
        if(element['indice'] === "1"){
          this.gradestamanhos.push(element);
        }
        if(element['indice'] === "2"){
          this.gradescores.push(element);
        }
      });
    }
  }

  setModelConfig() {
    this.modelConfig = {
      codigo: new CMInputConfig({
        form: this,
        caption: "Código",
        maxlength: 30,
        padInfo: { size: 30 },
        required: true,
        disabledFunc: () =>
          this.usaCopiaProduto() || this.codigoProdutoSequencial(),
      }),
      codigofabricante: new CMInputConfig({
        form: this,
        caption: "Código do Fabricante",
        maxlength: 60,
        disabledFunc: () => this.usaCopiaProduto(),
      }),
      ean: new CMInputConfig({
        form: this,
        caption: "EAN",
        maxlength: 14,
        disabledFunc: () => this.usaCopiaProduto(),
      }),
      descr: new CMInputConfig({
        form: this,
        caption: "Descrição",
        maxlength: 60,
        required: true,
        disabledFunc: () => this.usaCopiaProduto(),
      }),
      un: new CMInputConfig({
        form: this,
        caption: "Un. Medida",
        maxlength: 6,
        required: true,
        disabledFunc: () => this.usaCopiaProduto(),
      }),
      cadastro: new CMInputConfig({
        form: this,
        caption: "Data de cadastro",
        mask: "99/99/9999",
        required: true,
        disabled: true,
      }),
      dataultimacompra: new CMInputConfig({
        form: this,
        caption: "Data Última Compra",
        mask: "99/99/9999",
      }),
      vultimacompra: new CMInputConfig({
        form: this,
        caption: "Valor Última Compra",
        dataType: "boolean(2)",
      }),
      observacao: new CMInputConfig({
        form: this,
        caption: "Observação",
        maxlength: 40,
      }),
      ativo: new CMInputConfig({
        form: this,
        caption: "Ativo",
        maxlength: 40,
      }),
      grupo: new CMInputConfig({
        form: this,
        caption: "Grupo",
        upper: true,
        visibleFunc: () => FuncsGlobais.paramEmpresa.modoOperacao === "Nivel2",
        disabledFunc: () => this.copiaProdutoFiscal(),
        pesqFunc: (sender) => {
          GrupoDeProdutoBrowseComponent.pesquisa(sender, this.model, "grupo");
        },
      }),
      subgrupo: new CMInputConfig({
        form: this,
        caption: "Sub-Grupo",
        upper: true,
        visibleFunc: () => FuncsGlobais.paramEmpresa.modoOperacao === "Nivel2",
        disabledFunc: () => this.copiaProdutoFiscal(),
        pesqFunc: (sender) => {
          SubGrupoDeProdutoBrowseComponent.pesquisa(
            sender,
            this.model,
            "subgrupo"
          );
        },
      }),
      precocompra: new CMInputConfig({
        form: this,
        caption: "Preço de custo na entrada",
        dataType: "double(2)",
        disabledFunc: () => this.copiaProdutoPreco(),
        onExit: () => {
          this.calculaMarkUpAll();
        },
      }),
      precocompra2: new CMInputConfig({
        form: this,
        caption: "Preço de custo na entrada",
        dataType: "double(2)",
        disabled: true,
      }),
      atualizaprecocompra: new CMInputConfig({
        form: this,
        caption: "Atualiza preço de compra e custo ao salvar NFe de entrada",
        disabledFunc: () => this.copiaProdutoPreco(),
      }),
      precos: new CMGridEditConfig({
        fields: {
          empresapreco: new CMInputConfig({
            form: this,
            caption: "Preço",
            gridConfig: new CMGridEditConfigColumn({
              getValue: (row) => this.getValueObject(row, "empresapreco.nome"),
            }),
          }),
          precovendacalculado: new CMInputConfig({
            form: this,
            caption: "Preço de Venda Calculado",
            dataType: "double(2)",
            disabled: true,
          }),
          comissao: new CMInputConfig({
            form: this,
            caption: "% Comissão",
            dataType: "double(2)",
            disabledFunc: () => this.copiaProdutoPreco(),
            onExit: () => {
              this.calculaMarkUpCurrent();
            },
          }),
          custoadm: new CMInputConfig({
            form: this,
            caption: "% Custos Administrativos",
            dataType: "double(2)",
            disabledFunc: () => this.copiaProdutoPreco(),
            onExit: () => {
              this.calculaMarkUpCurrent();
            },
          }),
          lucro: new CMInputConfig({
            form: this,
            caption: "% Lucro",
            dataType: "double(2)",
            disabledFunc: () => this.copiaProdutoPreco(),
            onExit: () => {
              this.calculaMarkUpCurrent();
            },
          }),
          juros: new CMInputConfig({
            form: this,
            caption: "% Juros",
            dataType: "double(2)",
            disabledFunc: () => this.copiaProdutoPreco(),
            onExit: () => {
              this.calculaMarkUpCurrent();
            },
          }),
          outros: new CMInputConfig({
            form: this,
            caption: "% Outros",
            dataType: "double(2)",
            disabledFunc: () => this.copiaProdutoPreco(),
            onExit: () => {
              this.calculaMarkUpCurrent();
            },
          }),
          impostos: new CMInputConfig({
            form: this,
            caption: "% Impostos",
            dataType: "double(2)",
            disabledFunc: () => this.copiaProdutoPreco(),
            onExit: () => {
              this.calculaMarkUpCurrent();
            },
          }),
          markup: new CMInputConfig({
            form: this,
            caption: "MarkUp Calculado",
            dataType: "double(4)",
            disabled: true,
          }),
          vuncom: new CMInputConfig({
            form: this,
            caption: "Preço de venda",
            dataType: "double(2)",
            disabledFunc: () => {
              if (this.copiaProdutoPreco()) return true;
              else if (this.atualiza_vUnCom(this.modelConfig.precos.current))
                return true;
              else return false;
            },
          }),
          atuvuncom: new CMInputConfig({
            form: this,
            caption: "Ao alterar o custo:",
            values: [
              { value: "Sim", text: "Altera Margem" },
              { value: "Não", text: "Altera Preço de Venda" },
            ],
            disabledFunc: () => this.copiaProdutoPreco(),
            onChange: () => {
              this.calculaPrecoVendaCurrent();
            },
          }),
        },
      }),
      fiscal: {
        padraodeoperacao: new CMInputConfig({
          form: this,
          caption: "Padrão de Operação",
          disabledFunc: () => this.copiaProdutoFiscal(),
          pesqFunc: (sender) => {
            PadraoDeOperacaoBrowseComponent.pesquisa(sender, this.model.fiscal);
          },
        }),
        ibpt: new CMInputConfig({
          form: this,
          caption: "IBPT (NCM)",
          required: true,
          disabledFunc: () => this.copiaProdutoFiscal(),
          pesqFunc: (sender) => {
            IBPTBrowseComponent.pesquisa(sender, this.model.fiscal);
          },
        }),
        cest: new CMInputConfig({
          form: this,
          caption: "CEST",
          disabledFunc: () => this.copiaProdutoFiscal(),
          pesqFunc: (sender) => {
            let ncm: string = "";
            if (this.model.fiscal && this.model.fiscal.ibpt)
              ncm = this.model.fiscal.ibpt.codigo;
            CESTBrowseComponent.pesquisa(sender, this.model.fiscal, ncm);
          },
        }),
        origem: new CMInputConfig({
          form: this,
          caption: "Origem da Mercadoria",
          dataType: "integer",
          iconButton: "help",
          execPesqOnlyOnExit: false,
          disabledFunc: () => this.copiaProdutoFiscal(),
          pesqFunc: (sender) => {
            CMFormGlobaisComponent.showMessage(
              "Origem da Mercadoria",
              '<div style="padding: 20px;">' +
                "<p><strong>0</strong> - Nacional, exceto as indicadas nos códigos 3, 4, 5 e 8;</p>" +
                "<p><strong>1</strong> - Estrangeira - Importação direta, exceto a indicada no código 6;</p>" +
                "<p><strong>2</strong> - Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7;</p>" +
                "<p><strong>3</strong> - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40% e inferior ou igual a 70%;</p>" +
                "<p><strong>4</strong> - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legislações citadas nos Ajustes;</p>" +
                "<p><strong>5</strong> - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%;</p>" +
                "<p><strong>6</strong> - Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX e gás natural;</p>" +
                "<p><strong>7</strong> - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante lista CAMEX e gás natural.</p>" +
                "<p><strong>8</strong> - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%</p>" +
                "</div>"
            );
          },
        }),
      },
      gradestamanhos: new CMListCheckBoxConfig({
        form: this,
        listFunc: () => {
          return this.gradestamanhos;
        },
        propValue: "codigo",
        columns: 1,
        striping: true,
        required: false,
        onGetValue: (nodeItem, nodeValue) => {
          return [];
        },
        onUnSelect: (item) => {
          let indexOfObject = this.gradesconfig.findIndex(object =>{
            return object.id === item.id;
          });
          if (indexOfObject > -1) this.gradesconfig.splice(indexOfObject, 1);
        },
        onSelect: (item) => {
          this.gradesconfig.push({
            id: item.id,
            codigo: item.codigo,
            indice: item.indice,
          });
        },
        
      }),
      gradescores: new CMListCheckBoxConfig({
        form: this,
        listFunc: () => {
          return this.gradescores;
        },
        propValue: "codigo",
        columns: 1,
        striping: true,
        required: false,
        onGetValue: (nodeItem, nodeValue) => {
          return [];
        },
        onUnSelect: (item) => {
          let indexOfObject = this.gradesconfig.findIndex(object =>{
            return object.id === item.id;
          });
          if (indexOfObject > -1) this.gradesconfig.splice(indexOfObject, 1);
        },
        onSelect: (item) => {
          this.gradesconfig.push(item);
        },
      }),
      usagrade1: new CMInputConfig({
        form: this,
        caption: "Controla " + this.paramEmpresa.nomeGrade1,
        disabledFunc: () => this.usouGrade(1) || this.copiaProdutoGrade(),
        visibleFunc: () => {
          return this.paramEmpresa.usaGrade1;
        },
        onExit: () => {
          console.log(this.model);
        }
      }),
      usagrade2: new CMInputConfig({
        form: this,
        caption: "Controla " + this.paramEmpresa.nomeGrade2,
        disabledFunc: () => this.usouGrade(2) || this.copiaProdutoGrade(),
        visibleFunc: () => {
          return this.paramEmpresa.usaGrade2;
        },
        onExit: () => {
          console.log(this.model);
        }
      }),
      usalote: new CMInputConfig({
        form: this,
        caption: "Controla lote",
        disabledFunc: () => this.model.usoulote == "Sim",
        visibleFunc: () => {
          return this.paramEmpresa.usaLote;
        },
      }),
      usavalidadelote: new CMInputConfig({
        form: this,
        caption: "Controla validade do lote",
        visibleFunc: () => {
          return this.paramEmpresa.usaLote && this.model.usalote == "Sim";
        },
      }),
      grades: new CMGridEditConfig({
        form: this,
        caption: "Grade",
        requiredFunc: () => this.usaGrade(),
        disabledFunc: () => this.copiaProdutoGrade(),
        onAllowRemoveItemCallback: (callback, current) =>
          this.onAllowRemoveGrade(callback, current),
        fields: {
          selecionado: new CMInputConfig({
            form: this,
            value: "Sim",
            gridConfig: new CMGridEditConfigColumn({
              tipo: "checkbox",
              width: "0px",
              deleteFunc: () => {

              }
            }),
          }),
          grade1: new CMInputConfig({
            form: this,
            required: true,
            caption: this.paramEmpresa.nomeGrade1,
            upper: true,
            visibleFunc: () => {
              return this.model.usagrade1 == "Sim";
            },
            gridConfig: new CMGridEditConfigColumn({
              tipo: "input-pesquisa",
              getValue: (row) => {
                return this.getValueObject(row, "grade1.codigo");
              },
            }),
            pesqFunc: (sender) =>
              GradeBrowseComponent.pesquisa(
                sender,
                this.modelConfig.grades.grid.current,
                "1"
              ),
          }),
          grade2: new CMInputConfig({
            form: this,
            required: true,
            caption: this.paramEmpresa.nomeGrade2,
            upper: true,
            visibleFunc: () => {
              return this.model.usagrade2 == "Sim";
            },
            gridConfig: new CMGridEditConfigColumn({
              tipo: "input-pesquisa",
              getValue: (row) => {
                return this.getValueObject(row, "grade2.codigo");
              },
            }),
            pesqFunc: (sender) =>
              GradeBrowseComponent.pesquisa(
                sender,
                this.modelConfig.grades.grid.current,
                "2"
              ),
          }),
        },
      }),
      fornecedores: new CMGridEditConfig({
        form: this,
        required: false,
        onNewItem: (model) => {
          Object.assign(model, {
            taxaconversao: "1,00",
          });
        },
        disabledFunc: () => this.copiaProdutoFornecedor(),
        caption: "Fornecedores",
        fields: {
          fornecedor: new CMInputConfig({
            form: this,
            required: true,
            caption: "Fornecedor",
            upper: true,
            gridConfig: new CMGridEditConfigColumn({
              tipo: "input-pesquisa",
              getValue: (row) => {
                return this.getValueObject(row, "fornecedor.codigo");
              },
              getBottomLabelColumn: (row) => {
                return this.getValueObject(row, "fornecedor.nome");
              },
            }),
            pesqFunc: (sender) =>
              PessoaBrowseComponent.pesquisa(
                sender,
                this.modelConfig.fornecedores.grid.current,
                "Fornecedor",
                "fornecedor"
              ),
          }),
          codigonofornecedor: new CMInputConfig({
            form: this,
            required: true,
            caption: "Código no fornecedor",
            maxlength: 60,
            upper: true,
            gridConfig: new CMGridEditConfigColumn(),
          }),
          taxaconversao: new CMInputConfig({
            form: this,
            caption: "Taxa Conversão para Unidade",
            dataType: "double(2)",
            gridConfig: new CMGridEditConfigColumn(),
          }),
          observacao: new CMInputConfig({
            form: this,
            caption: "Observação da Taxa de Conversão",
            maxlength: 100,
            gridConfig: new CMGridEditConfigColumn(),
          }),
        },
      }),
      precospromocao: new CMGridEditConfig({
        form: this,
        required: false,
        onNewItem: (model) => {
          Object.assign(model, {
            vuncom: "0,00",
            origem: "Produto",
          });
        },
        disabledFunc: () => this.copiaProdutoFornecedor(),
        caption: "Preços de Promoção",
        fields: {
          nome: new CMInputConfig({
            form: this,
            required: true,
            caption: "Nome",
            maxlength: 10,
            gridConfig: new CMGridEditConfigColumn({
              disabledFunc: (row) => this.podeEditarPrecoPromocao(row),
            }),
          }),
          datainicio: new CMInputConfig({
            form: this,
            required: true,
            caption: "Data de Início",
            mask: "99/99/9999",
            gridConfig: new CMGridEditConfigColumn({
              disabledFunc: (row) => this.podeEditarPrecoPromocao(row),
            }),
          }),
          datatermino: new CMInputConfig({
            form: this,
            required: true,
            caption: "Data de Término",
            mask: "99/99/9999",
            gridConfig: new CMGridEditConfigColumn({
              disabledFunc: (row) => this.podeEditarPrecoPromocao(row),
            }),
          }),
          vuncom: new CMInputConfig({
            form: this,
            required: true,
            caption: "Valor",
            dataType: "double(2)",
            gridConfig: new CMGridEditConfigColumn({
              disabledFunc: (row) => this.podeEditarPrecoPromocao(row),
            }),
          }),
          origem: new CMInputConfig({
            form: this,
            caption: "Origem",
            gridConfig: new CMGridEditConfigColumn({
              readOnly: true,
            }),
          }),
        },
      }),
    };
  }
  gradeExiste(codigo: any) {
    let result = false;
    this.gradestamanhos.forEach((item) => {
      if (item.codigo === codigo) {
        result = true; 
      }
    })
    this.gradescores.forEach((item) => {
      if (item.codigo === codigo){
        result = true; 
      }
    })
    return result;
  }

  saveNovaGrade(formBrowse, codigo, indice) {

    console.log('CODIGO: ',codigo);

    if (codigo !== undefined) {
      this.adicionouNovaGrade = true;
      let modelgrade;
      modelgrade = {
        codigo: codigo,
        id: CMInputConfig.getRandomInt(1, 9999)*-1,
        indice: indice,
        visibilidade: "User"
      }

      CMFormGlobaisComponent.showSpinner();
      let dataSend = {};
      dataSend["_fieldId"] = this.fieldNameId();
      dataSend[this.fieldNameId()] = this.idDataModel;
      dataSend["modeldata"] = modelgrade;
      this.beforeSaveModelData(formBrowse, dataSend);
      console.log('DATASEND-------------- ', dataSend);
      this.postSubscribe(
          "/api/grade/saveobject",
        JSON.stringify(dataSend),
        (result) => {
          if (result["result"] === "ok") {
            modelgrade[this.fieldNameId()] = result[this.fieldNameId()];
          };
          if (indice === "1") {
            this.gradestamanhos.push({
              codigo: codigo,
              id: result["id"],
              indice: indice
            })
          }
          else if (indice === "2") {
            this.gradescores.push({
              codigo: codigo,
              id: result["id"],
              indice: indice
            })
          };
          GradeBrowseComponent.addGrade({
            codigo: codigo,
            id: result["id"],
            indice: indice
          });
          CMFormGlobaisComponent.hideSpinner();
        },
        (error) => {
          CMFormGlobaisComponent.hideSpinner();
          this.showMessageErrorHTTP("saveModelData", error);
        }
      );
    }
  }

  podeEditarPrecoPromocao(row) {
    let r = true;
    if (row) {
      r = row.origem !== "Produto";
    }
    return r;
  }

  calculaMarkUpAll() {
    this.model.precos.forEach((item) => {
      this.calculaMarkUp(item);
    });
  }

  calculaMarkUpCurrent() {
    this.calculaMarkUp(this.modelConfig.precos.current);
  }

  calculaMarkUp(item) {
    let somaperc: number =
      this.valueToFloat(item.comissao, 0) +
      this.valueToFloat(item.custoadm, 0) +
      this.valueToFloat(item.juros, 0) +
      this.valueToFloat(item.outros, 0) +
      this.valueToFloat(item.impostos, 0) +
      this.valueToFloat(item.lucro, 0);
    if (somaperc >= 100) {
      somaperc = 99.99;
      CMFormGlobaisComponent.toastErro(
        "A soma dos <b>Percentuais para Cálculo do MarkUp</b> não deve menor que 100%"
      );
    }
    item.markup = this.floatToValue(1 / ((100 - somaperc) / 100), 4);
    this.calculaPrecoVenda(item);
  }

  calculaPrecoVenda(item) {
    let precovendacalculado =
      this.valueToFloat(this.model.precocompra, 0) *
      this.valueToFloat(item.markup, 0);
    item.precovendacalculado = this.floatToValue(precovendacalculado);
    if (this.atualiza_vUnCom(item)) {
      item.vuncom = item.precovendacalculado;
    }
  }

  atualiza_vUnCom(item) {
    return item.atuvuncom === "Sim";
  }

  calculaPrecoVendaCurrent() {
    this.calculaPrecoVenda(this.modelConfig.precos.current);
  }

  get paramEmpresa() {
    return FuncsGlobais.paramEmpresa;
  }

  usaGrade() {
    return this.model.usagrade1 == "Sim" || this.model.usagrade2 == "Sim";
  }

  exibeGrades() {
    if (this.model.grades.length === 1) {
      if ((this.model.grades[0].grade1.codigo === 'GRA1_ND') && (this.model.grades[0].grade2.codigo === 'GRA2_ND')) {
        return false;
      }
    }
    return this.model.usagrade1 == "Sim" || this.model.usagrade2 == "Sim";
  }

  mostraGerarTodasGradesDisponiveis() {
    //return this.usaGrade() && this.model.id < 0;
    return this.usaGrade() && this.model.grades.length <= 1;
  }

  gerarTodasGradesDisponiveis() {
    CMFormGlobaisComponent.showSpinner();

    console.log(this.model);

    if ((this.model.usagrade1 === 'Sim' && this.model.usagrade2 === 'Não')||(this.model.usagrade1 === 'Não' && this.model.usagrade2 === 'Sim')){
      console.log(this.model);
      
      this.postSubscribe(
        "/api/produto/GerarGradesSelecionadas",
        JSON.stringify(this.gradesconfig),
        (result: any) => {
          this.model.grades = result.grades;
          CMFormGlobaisComponent.hideSpinner();
        },
        (error) => {
          CMFormGlobaisComponent.hideSpinner();
          CMFormGlobaisComponent.showMessageError(error);
        }
      );
    }
    else if (this.model.usagrade1 === 'Sim' && this.model.usagrade2 === 'Sim'){
      console.log(this.model);
      
      this.postSubscribe(
        "/api/produto/GerarCombinacoesDeGrades",
        JSON.stringify(this.gradesconfig),
        (result: any) => {
          this.model.grades = result.grades;
          CMFormGlobaisComponent.hideSpinner();
        },
        (error) => {
          CMFormGlobaisComponent.hideSpinner();
          CMFormGlobaisComponent.showMessageError(error);
        }
      );
    }

  }

  usouGrade(indice) {
    let r = this.model.grades.length > 0;
    if (r) {
      r = false;
      for (let i = 0; i < this.model.grades.length; i++) {
        const grade = this.model.grades[i]["grade" + indice];
        if (grade) {
          if (grade.visibilidade == "User") {
            r = true;
            break;
          }
        }
      }
    }
    return r;
  }

  onAllowRemoveGrade(callback, current) {
    CMFormGlobaisComponent.showSpinner();
    this.postSubscribe(
      "/api/" + this.controllerName + "/PodeRemoverGrade",
      JSON.stringify({
        idproduto: this.model.id,
        idgrade: current.id,
      }),
      (r) => {
        if (r["result"] == "ok") callback("");
        else callback(r["msg"]);
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        CMFormGlobaisComponent.showMessageError(error);
      }
    );
  }

  usaCopiaProduto() {
    return !this.disabledAll && this.paramEmpresa.usaCopiaProduto;
  }

  copiaProdutoPreco() {
    return (
      this.paramEmpresa.usaCopiaProduto && this.paramEmpresa.copiaProdutoPreco
    );
  }

  copiaProdutoFiscal() {
    return (
      this.paramEmpresa.usaCopiaProduto && this.paramEmpresa.copiaProdutoFiscal
    );
  }

  codigoProdutoSequencial() {
    return this.paramEmpresa.codigoProdutoSequencial;
  }

  copiaProdutoGrade() {
    return (
      this.paramEmpresa.usaCopiaProduto && this.paramEmpresa.copiaProdutoGrade
    );
  }

  copiaProdutoFornecedor() {
    return (
      this.paramEmpresa.usaCopiaProduto &&
      this.paramEmpresa.copiaProdutoFornecedor
    );
  }

  getColumnsPrecos() {
    if (this.paramEmpresa.modoOperacao === "Nivel1") return "12";
    else return "9";
  }

  afterGetModelData() {
    super.afterGetModelData();

    console.log(this.model);
    
  }

}
